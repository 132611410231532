// ExternalRedirect.jsx
import React, { useEffect } from 'react';
import {useLocation} from "react-router";

const ExternalRedirect = ({ to }) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const redirect = query.get('redirect');

    useEffect(() => {
        if (!redirect) {
            const finalTo = `${to}`;
            // Only redirect if the current URL isn’t already the target
            if (window.location.href !== finalTo) {
                window.location.replace(finalTo);
            }
        } else {
            const finalTo = `${to}?redirect=${redirect}`;
            // Only redirect if the current URL isn’t already the target
            if (window.location.href !== finalTo) {
                window.location.replace(finalTo);
            }
        }
    }, [to]);

    return null;
};

export default ExternalRedirect;
