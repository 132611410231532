export async function fetchWithAuth(url, options = {}) {
    // Retrieve the access token from localStorage
    const localToken = window.localStorage.getItem("token");

    if (localToken) {
        // --- CASE 1: Token found in localStorage ---
        // Prepare the options with the Authorization header
        let mergedOptions = {
            ...options,
            credentials: "same-origin", // Do not include cookies for this request
            headers: {
                ...options.headers,
                Authorization: `Bearer ${localToken}`,
            },
        };

        let response = await fetch(url, mergedOptions);

        // If the response is unauthorized, attempt to refresh the token
        if (response.status === 401) {
            const refreshResponse = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/auth/refresh`,
                {
                    method: "POST",
                    credentials: "include", // Cookies are needed here for the refresh token
                }
            );

            if (refreshResponse.ok) {
                const data = await refreshResponse.json();
                const newAccessToken = data.access_token;

                if (!newAccessToken) {
                    throw new Error("No access token in refresh response");
                }

                // Store the new access token in localStorage
                window.localStorage.setItem("token", newAccessToken);

                // Retry the original request with the refreshed token
                mergedOptions = {
                    ...options,
                    credentials: "same-origin",
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${newAccessToken}`,
                    },
                };

                response = await fetch(url, mergedOptions);
            } else {
                throw new Error("Refresh token invalid. Please log in again.");
            }
        }

        return response;
    } else {
        // --- CASE 2: No token in localStorage ---
        // Use cookie-based authentication by including credentials
        let mergedOptions = {
            ...options,
            credentials: "same-origin",
        };

        let response = await fetch(url, mergedOptions);

        // If the response is unauthorized, attempt to refresh the token
        if (response.status === 401) {
            const refreshResponse = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/auth/refresh`,
                {
                    method: "POST",
                    credentials: "include", // Cookies are needed here for the refresh token
                }
            );

            if (refreshResponse.ok) {
                const data = await refreshResponse.json();
                const newAccessToken = data.access_token;

                if (!newAccessToken) {
                    throw new Error("No access token in refresh response");
                }

                // Store the new access token in localStorage
                window.localStorage.setItem("token", newAccessToken);

                // Retry the original request using the new token.
                // Now that we have a token, switch to omitting credentials.
                const retryOptions = {
                    ...options,
                    credentials: "same-origin",
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${newAccessToken}`,
                    },
                };

                response = await fetch(url, retryOptions);
            } else {
                throw new Error("Refresh token invalid. Please log in again.");
            }
        }

        return response;
    }
}

export const logoutUser = async () => {
    try {
        await fetch(`${process.env.REACT_APP_API_V2_URL}/auth/logout`, {
            method: "POST",
            credentials: "include",
        })
    } catch (error) {
        console.error("Error logging out:", error)
    }
}