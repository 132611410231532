import { createSlice } from "@reduxjs/toolkit";
import {logoutUser} from "../api";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            token: "",
            type: "",
            mktPermission: false,
            tableAdminPermission: false,
        }
    },
    reducers: {
        login: (state, action) => {
            state.user.username = action.payload.username;
            state.user.password = action.payload.password;
            state.user.token = action.payload.token;
        },
        logout: (state) => {
            state.user = {
                token: "",
                type: "",
                mktPermission: false,
                tableAdminPermission: false,
            }
            if (typeof window !== "undefined") {
                localStorage.removeItem("token")
            }
        },
        setType: (state, action) => {
            state.user.type = action.payload;
        },
        setMktPermission: (state, action) => {
            state.user.mktPermission = action.payload;
        },
        setTableAdminPermission: (state, action) => {
            state.user.tableAdminPermission = action.payload;
        }
    }
});

export const { login, logout, setType, setMktPermission, setTableAdminPermission } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;