import React, {useEffect, useState} from 'react';
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import Products from "./Products";
import CarroButton from "./CarroButton";
import {motion} from "framer-motion";
import {useLocation, useNavigate} from "react-router";
import AccountHeader from "./AccountHeader";
import QRCode from "qrcode.react";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";
import {splitNumber} from "./functions/numberFormat";
import BalanceRender from "./BalanceRender";
import { fetchWithAuth, logoutUser } from '../api';

const MyAccount = () => {

    const token = window.localStorage.getItem('token');
    const [userData, setUserData] = useState(null);
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "credentials": "same-origin"
            }
        });

        const response = await request.json();
        setUserData(response.profile);
        setIsLoaded(true);
    }

    const closeSession = () => {
        localStorage.removeItem("token");
        logoutUser();

        if (window.history.length > 1) {
            navigate(-1);
        } else {
            navigate("/");
        }

        // Perform the page reload after a short delay to allow navigation to complete
        setTimeout(() => window.location.reload(), 100);
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 200);

        // Cleanup function
        return () => {
            clearTimeout(timerId);
        };
    }, [token]);


    return (
            <div className="menu-container" id="menu-container">
                <motion.div
                    initial={{
                        x: location.state?.direction === "left" ? "-100vw" : "100vw",
                    }}
                    animate={{ x: 0 }}
                    exit={{ x: "100vw" }}
                    transition={{ duration: 0.2 }}
                >
                <div className="mt-4" id="menu-body" style={{overflowY: "scroll", overflowX: "hidden"}}>
                    {!isLoaded ? (
                        <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                            <CircularProgress color="secondary" size={100} />
                        </div>
                    ) : (
                    <div className="container-fluid">
                        <div className="row justify-content-center text-center mb-2" style={{color: "black"}}>
                            <div className="col-12">
                                <p className={"mb-0"} style={{fontSize: "1.2rem", fontWeight: "normal", letterSpacing: "0.1em"}}>ID: {userData?.user_code_id}</p>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <QRCode value={userData?.user_code_id} bgColor="#ffffff" fgColor="#000000" className="img-fluid" style={{maxWidth: "250px", border: "1px solid black", borderRadius: "1rem", padding: "1rem", width: "100%", height: "auto"}} />
                            </div>
                        </div>
                        <div className="row justify-content-center text-center mt-3" style={{color: "black"}}>
                            <div className="col-12">
                                <p className={"mb-0"} style={{fontSize: "1.4rem", fontWeight: "bold", lineHeight: 0.8}}>{userData?.user_name} {userData?.user_surname}</p>
                                <p className={"mb-0 mt-0"} style={{opacity: "0.5", fontSize: "0.9rem"}}><small>Compartí tu QR para cargar dinero en tu cuenta</small></p>
                            </div>
                        </div>
                        {userData?.balances.length > 0 ? (
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <hr style={{border: "1px solid black"}} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className={"mb-0"} style={{fontSize: "1.2rem", fontWeight: "bold", color: "black"}}>Saldo por evento</p>
                                    </div>
                                </div>
                                {userData?.balances?.map((balance, index) => (
                                    <div className="row align-items-center mt-2" style={{color: "black"}} key={index}>
                                        <div className="col">
                                            <p className={"mb-0"} style={{fontSize: "1.2rem", fontWeight: "normal"}}>{balance.menu_name}</p>
                                        </div>
                                        <div className="col-auto">
                                            <BalanceRender balance={balance.balance} />
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : null}
                    </div>
                    )}
                </div>
                </motion.div>
                <div className="row mt-5">
                    <div className="col-12 d-flex justify-content-center">
                        <button className="close-session-button-menu-account" onClick={closeSession}>Cerrar Sesión</button>
                    </div>
                </div>
            </div>
    );
};



export default MyAccount;