import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { collapseSearchBar, selectExpandedSearchBar } from "../redux/carroSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaCalendarAlt, FaRegUserCircle } from "react-icons/fa";
import { selectUser } from "../redux/userSlice";
import { useLocation } from "react-router";
import { Skeleton } from "@mui/lab";
import { selectMenu, selectMenuIsLoaded, setMenu } from "../redux/menuSlice";
import { splitNumber } from "./functions/numberFormat";
import MenuAuthentication from "./MenuAuthentication";
import { fetchWithAuth } from '../api';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MenuHeader = (props) => {
  const navigate = useNavigate();
  const expanded = useSelector(selectExpandedSearchBar);
  const menu = useSelector(selectMenu);
  const menuIsLoaded = useSelector(selectMenuIsLoaded);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();

  const [userBalance, setUserBalance] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEventsDialog, setOpenEventsDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState('');

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => setOpenConfirm(false);

  // Handlers para el diálogo de eventos
  const handleOpenEventsDialog = () => {
    setOpenEventsDialog(true);
  };
  const handleCloseEventsDialog = () => {
    setOpenEventsDialog(false);
  };

  // Cuando el usuario selecciona un evento, se actualiza la URL con el evento elegido.
  const handleEventSelection = (selectedEvent) => {
    console.log("Selected Event:", selectedEvent);
    setOpenEventsDialog(false);
    // Construir la URL con el nuevo evento.
    let eventUrl = selectedEvent.event_url;
    // Si la URL inicia con '/', la removemos para concatenar correctamente.
    if (eventUrl.startsWith('/')) {
      eventUrl = eventUrl.slice(1);
    }
    const newUrl = `/menu/${props.id}/${eventUrl}`;
    // Solo navegamos si la URL actual es distinta.
    if (location.pathname !== newUrl) {
      navigate(newUrl);
    }
    // Opcional: notificar al componente padre del cambio de evento.
    // props.onEventChange(selectedEvent);
  };

  const fetchData = async (id) => {
    const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/menu?menu_id=${id}&event=${props.event}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "credentials": "same-origin",
        "Authorization": `Bearer ${user.token}`,
      }
    });
    const response = await request.json();
    if (response.status === "success") {
      dispatch(setMenu(response.menu));
    }
  };

  const fetchUser = async () => {
    const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/profile-users?menu_id=${props.id}&event=${props.event}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.token}`,
        "credentials": "same-origin"
      }
    });
    const response = await request.json();
    if (response?.status === "success") {
      setUserBalance(response.balance);
    }
  };

  function handleBackButtonClick() {
    const searchContainer = document.querySelector('.search-container');
    const searchIcon = document.querySelector('.search-icon');
    const searchInput = document.querySelector('.search-input');

    if (props.returnButton) {
      navigate(props.returnButton);
      return;
    }

    if (expanded) {
      try {
        searchIcon.classList.remove('active');
        searchInput.classList.remove('active');
        searchContainer.classList.remove('expanded');
        dispatch(collapseSearchBar());
        props.setSearch('');
      } catch (error) {
        if (window.location.pathname.split("/").pop() === "carro") {
          const pathArray = window.location.pathname.split("/");
          pathArray.pop();
          const newPathname = pathArray.join("/");
          navigate(newPathname);
        } else {
          if (window.history.length > 1) navigate(-1);
        }
      }
    } else {
      if (window.location.pathname.split("/").pop() === "carro") {
        const pathArray = window.location.pathname.split("/");
        pathArray.pop();
        const newPathname = pathArray.join("/");
        navigate(newPathname);
      } else {
        if (window.history.length > 1) navigate(-1);
      }
    }
  }

  const menuCatSearchActive = () => {
    const pathArray = window.location.pathname.split("/");
    const secondSegment = pathArray[1];
    if (secondSegment !== "menu") {
      return false;
    }
    for (const segment of pathArray) {
      if (segment.includes("carro") || segment.includes("item")) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const requests = setTimeout(() => {
      const pathArray = window.location.pathname.split("/");
      let id;
      if (props.id && !menuIsLoaded) {
        fetchData(props.id);
      } else {
        id = pathArray[2];
      }
      if (!menuIsLoaded && (!menu || menu.id !== id) && pathArray.length !== 3 && pathArray[1] === "menu") {
        fetchData(id);
      }
    }, 300);
    return () => clearTimeout(requests);
  }, [menu, menuIsLoaded]);

  useEffect(() => {
    if (user.token) {
      fetchUser();
      const interval = setInterval(fetchUser, 15000);
      return () => clearInterval(interval);
    }
  }, [user]);

  useEffect(() => {
    if (props.activeEvents && props.activeEvents.length > 0) {
      const defaultEvent = props.activeEvents.find(e => e.default);
      if (defaultEvent) {
        setSelectedEventId(String(defaultEvent.event_id));
      }
    }
  }, [props.activeEvents]);
  

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="menu-title-container" style={{ backgroundColor: menu?.header_color }}>
            {!menuCatSearchActive.call() || expanded ? (
              <button
                id="menu-go-back-btn"
                onClick={handleBackButtonClick}
                style={{ backgroundColor: "transparent", border: "none", color: "white" }}
              >
                <BiArrowBack size={25} />
              </button>
            ) : (
              <button
                onClick={handleOpenEventsDialog}
                style={{ backgroundColor: "transparent", border: "none", color: "white" }}
              >
                <FaCalendarAlt size={25} />
              </button>
            )}
            {menu?.name ? (
              userBalance?.balance ? (
                <div className="menu-header-balance-container text-center">
                  <p className="menu-title-balance text-center mt-0 mb-0">{menu?.name}</p>
                  <div className="menu-balance">
                    <p className="mb-0 mt-0" style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Saldo: $ {splitNumber(userBalance?.balance).integer}
                      <sup style={{ fontSize: "0.4rem", marginLeft: "3px", top: "-7px", position: "relative" }}>
                        {splitNumber(userBalance?.balance).decimal}
                      </sup>
                    </p>
                  </div>
                </div>
              ) : (
                <p className="menu-title">{menu?.name}</p>
              )
            ) : (
              <Skeleton variant="text" width={200} height={30} />
            )}
            {user.token ? (
              <Link to="/my-account" style={{ color: "white" }}>
                <FaRegUserCircle size={25} />
              </Link>
            ) : (
              <button
                id="menu-login-btn"
                onClick={handleOpenConfirm}
                style={{ backgroundColor: "transparent", border: "none", color: "white" }}
              >
                <FaRegUserCircle size={25} />
              </button>
            )}
          </div>
        </div>
      </div>
      <MenuAuthentication openConfirm={openConfirm} handleCloseConfirm={handleCloseConfirm} id={props.id} event={props.event} />

      <Dialog
        open={openEventsDialog}
        onClose={() => {
          setSelectedEventId('');
          handleCloseEventsDialog();
        }}
        PaperProps={{
          style: {
            borderRadius: 15,
            padding: '16px',
            maxWidth: '400px',
            margin: 'auto',
            position: 'relative'
          },
        }}
      >
        <IconButton
            onClick={() => {
              setSelectedEventId('');
              handleCloseEventsDialog();
            }}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle
          style={{
            textAlign: 'center',
            paddingBottom: 0,
            paddingRight: '40px',
            position: 'relative'
          }}
        >
          <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
            Selecciona un evento
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              aria-label="active-events"
              name="active-events-group"
              value={selectedEventId}
              onChange={(event) => setSelectedEventId(event.target.value)}
            >
              {props.activeEvents && props.activeEvents.length > 0 ? (
                props.activeEvents.map((event, index) => (
                  <FormControlLabel
                    key={event.event_id || index}
                    value={String(event.event_id)}
                    control={<Radio />}
                    label={event.event_name}
                    style={{ padding: '6px 0' }}
                  />
                ))
              ) : (
                <FormControlLabel
                  value="none"
                  control={<Radio disabled />}
                  label="No hay eventos disponibles"
                />
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', padding: '8px 24px 16px' }}>
          <Button
            variant="contained"
            onClick={() => {
              const selectedEvent = props.activeEvents.find(e => String(e.event_id) === selectedEventId);
              if (selectedEvent) {
                handleEventSelection(selectedEvent);
              }
              setSelectedEventId('');
            }}
            style={{
              backgroundColor: '#1976d2',
              color: 'white',
              textTransform: 'none',
              borderRadius: 8,
            }}
            disabled={!selectedEventId || props.activeEvents.length === 0}
          >
            Seleccionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MenuHeader;
