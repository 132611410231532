import { useEffect, useMemo } from 'react';

export default function AppleSignIn({
    clientId,
    redirectURI,
    scope = 'name email',
    postAuthRedirectUrl = '', // New prop for the additional redirect after backend callback
    className = '',
  }) {
    // Generate a state object with a random nonce and the extra redirect URL,
    // then encode it as a base64 string.
    const generatedState = useMemo(() => {
      const nonce = Math.random().toString(36).substring(2, 15);
      const stateObj = { nonce, redirect: postAuthRedirectUrl };
      return btoa(JSON.stringify(stateObj));
    }, [postAuthRedirectUrl]);
  
    // Initialize AppleID.auth with the generated state
    useEffect(() => {
      if (typeof window !== 'undefined' && window.AppleID) {
        window.AppleID.auth.init({
          clientId, // Your Services ID registered in Apple Developer
          scope,
          redirectURI, // Must exactly match one of the return URLs registered
          state: generatedState, // Pass the generated state
          usePopup: false, // Enable popup mode to avoid full-page redirects
        });
      }
    }, [clientId, redirectURI, scope, generatedState]);
  
    const handleSignIn = () => {
      if (typeof window !== 'undefined' && window.AppleID) {
        window.AppleID.auth
          .signIn()
          .then(response => {
            // The response contains the authorization data (code, id_token, etc.)
            console.log('Apple sign in successful:', response);
            // Send response to your backend to exchange code for tokens
          })
          .catch(error => {
            console.error('Apple sign in failed:', error);
          });
      }
    };

  return (
    <>
        <div className="row text-center justify-content-center align-items-center mt-2">
            <div className="col-12">
                <button onClick={handleSignIn} className="google-btn w-100">
                    <img
                        src="/assets/images/apple-logo-icon-14895.png"
                        alt="Apple icon" className="google-icon img-fluid" />
                    Continuar con Apple
                </button>
            </div>
        </div>
    </>
  );
}
