import React, {useEffect, useState} from 'react';
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import Products from "./Products";
import CarroButton from "./CarroButton";
import {motion} from "framer-motion";
import {useLocation} from "react-router";
import AccountHeader from "./AccountHeader";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {Link} from "react-router-dom";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import SnackbarAlert from "./SnackbarAlert";
import { fetchWithAuth } from '../api';

const MyProfile = () => {

    const user = useSelector(selectUser);
    const location = useLocation();
    const [loading, setLoading] = React.useState(true);

    const [values, setValues] = React.useState({
        email: '',
        birth_date: new Date(),
        genero: ""
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const [snackOpen, setSnackOpen] = useState(false);
    const [snack, setSnack] = useState({
        severity: 'success',
        message: 'Usuario creado con éxito'
    });

    const fetchData = async () => {
        setLoading(true);

        const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            }
        });

        const data = await request.json();
        if (data.status === "success") {
            setValues({...values, ...data.profile, user_tel: data.profile.user_tel === 0 ? "" : data.profile.user_tel, birth_date: new Date(data.profile.birth_date) > new Date("1900-01-01") ? new Date(data.profile.birth_date) : ""});
            setLoading(false);
        }
    }

    const updateProfile = async () => {
        setLoading(true);
        const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            },
            body: JSON.stringify(values)
        });

        const data = await response.json();
        if (data.status === "success") {
            setLoading(false);
            setSnack({
                severity: 'success',
                message: 'Perfil actualizado con éxito'
            });
            setSnackOpen(true);
        }
        else {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: 'Error al actualizar el perfil'
            });
            setSnackOpen(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        updateProfile();
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 200);

        // Cleanup function
        return () => {
            clearTimeout(timerId);
        };
    }, [user.token]);


    return (
            <div>
                <motion.div
                    initial={{
                        x: location.state?.direction === "left" ? "-100vw" : "100vw",
                    }}
                    animate={{ x: 0 }}
                    exit={{ x: "100vw" }}
                    transition={{ duration: 0.2 }}
                >
                <div style={{overflowY: "scroll", overflowX: "hidden"}}>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                            <CircularProgress color="secondary" size={100} />
                        </div>
                    ) : (
                    <div className="container-fluid">
                        <SnackbarAlert message={snack.message} severity={snack.severity} open={snackOpen} setOpen={setSnackOpen} />
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <h3 className="">Mi Perfil</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form onSubmit={submitForm}>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <TextField fullWidth label="Nombre" variant="filled" value={values?.user_name} onChange={handleChange('user_name')} />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <TextField fullWidth label="Apellido" variant="filled" value={values?.user_surname} onChange={handleChange('user_surname')} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <TextField fullWidth type="number" onWheel={(e) => e.preventDefault()}  label="DNI" variant="filled" value={values?.user_dni} onChange={handleChange('user_dni')} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <TextField fullWidth label="Email" variant="filled" value={values?.email} onChange={handleChange('email')} disabled={true} helperText={"Si deseas modificar el email de tu cuenta, por favor contactate a info@flashpass.com.ar para que lo podamos resolver."} />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <TextField fullWidth label="Teléfono" type="number" onWheel={(e) => e.preventDefault()}  variant="filled" value={values?.user_tel} onChange={handleChange('user_tel')} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <LocalizationProvider adapterLocale={"es"} dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Fecha de nacimiento"
                                                            value={values?.birth_date}
                                                            inputFormat={'DD/MM/YYYY'}
                                                            onChange={(newValue) => {
                                                                setValues((prevValues) => ({
                                                                    ...prevValues,
                                                                    birth_date: newValue,
                                                                }));
                                                            }}
                                                            renderInput={(params) => <TextField fullWidth variant="filled" {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <FormControl variant="filled" fullWidth>
                                                        <InputLabel>Sexo</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Tipo"
                                                            value={values?.genero}
                                                            onChange={handleChange('genero')}
                                                        >
                                                            <MenuItem value={"M"}>Hombre</MenuItem>
                                                            <MenuItem value={"F"}>Mujer</MenuItem>
                                                            <MenuItem value={"O"}>Otros</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn w-100 btn-primary" type="submit">Guardar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                </motion.div>
            </div>
    );
};

export default MyProfile;