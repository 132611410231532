// ExternalEventRedirect.jsx
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ExternalEventRedirect = () => {
    const { eventId, promoterId } = useParams();
    const redirectUrl = `${process.env.REACT_APP_NEXT_PUBLIC_URL}/eventos/${eventId}${promoterId ? `/${promoterId}` : ''}`;

    useEffect(() => {
        // Perform the redirection
        window.location.href = redirectUrl;
    }, [redirectUrl]);

    return null; // No UI is rendered
};

export default ExternalEventRedirect;
