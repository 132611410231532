import React, { useEffect } from 'react';
import store from "../redux/configureStore";
import { login, logout } from "../redux/userSlice";
import { fetchWithAuth } from "../api";
import { Provider } from "react-redux";

const MainProvider = ({ children }) => {

    useEffect(() => {
        const checkAuth = async () => {
            // Helper: remove token from storage & Redux
            const removeTokenAndLogout = () => {
                store.dispatch(logout());
                localStorage.removeItem("token");
            };

            // Helper: returns true if path is under /organizations/*
            const shouldRedirectToLogin = () => {
                if (typeof window !== "undefined") {
                    return window.location.pathname.startsWith("/organizations/");
                }
                return false;
            };

            // Helper: construct /login?redirect=<fullUrl> and redirect the browser
            const redirectToLoginWithReturnUrl = () => {
                if (typeof window !== "undefined") {
                    // This captures the entire current URL, including protocol, domain, path, and query.
                    const fullUrl = window.location.href;
                    window.location.href = `/login?redirect=${encodeURIComponent(fullUrl)}`;
                }
            };

            // 1. Check localStorage for an access token.
            let token = localStorage.getItem("token");

            if (token) {
                // We have a token; try validating it.
                try {
                    await fetchWithAuth(`${process.env.REACT_APP_API_URL}/validate-jwt`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    // If validation succeeds, store token in Redux and do nothing further.
                    store.dispatch(login({ token }));
                    return;
                } catch (error) {
                    console.error("Error validating token:", error);
                    // Validation failed; clear token and proceed to refresh attempt.
                    removeTokenAndLogout();
                    token = null; // so it falls through to the refresh logic.
                }
            }

            // 2. If token was null OR validation failed, attempt to refresh.
            try {
                const refreshResponse = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/auth/refresh`,
                    {
                        method: "POST",
                        credentials: "include", // Include cookies for the refresh token.
                    }
                );

                if (refreshResponse.ok) {
                    const data = await refreshResponse.json();
                    const newAccessToken = data.access_token;

                    if (newAccessToken) {
                        store.dispatch(login({ token: newAccessToken }));
                        localStorage.setItem("token", newAccessToken);
                        return; // We’re done.
                    }
                }

                // If we got here, refresh failed or no token was returned.
                removeTokenAndLogout();

                // Only redirect if on /organizations/*
                if (shouldRedirectToLogin()) {
                    redirectToLoginWithReturnUrl();
                }
            } catch (error) {
                console.error("Error refreshing token:", error);
                removeTokenAndLogout();

                // Only redirect if on /organizations/*
                if (shouldRedirectToLogin()) {
                    redirectToLoginWithReturnUrl();
                }
            }
        };

        checkAuth();
    }, []);

    return <Provider store={store}>{children}</Provider>;
};

export default MainProvider;
