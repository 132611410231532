import React, {lazy, Suspense, useEffect} from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import HomePageUsersDark from "../pages/HomePageUsersDark";
import MyTickets from "../pages/MyTickets";
import ProfileUsers from "../pages/ProfileUsers";
import CreateAccount from "../pages/CreateAccount";
import ForgotPassword from "../pages/ForgotPassword";
import Login from "../components/Login";
import {Navigate, useLocation} from "react-router";
import {AnimatePresence} from "framer-motion";
import store from "../redux/configureStore";
import {login} from "../redux/userSlice";
import {createTheme, ThemeProvider} from "@mui/material";
import EventPreviewDark from "../pages/EventPreviewDark";
import RenderTicket from "../components/RenderTicket";
import ResetPassword from "../pages/ResetPassword";
import MenuOrder from "../components/MenuOrder";
import SuccessOrder from "../components/SuccessOrder";
import GoogleAuthenticate from "../components/GoogleAuthenticate";
import AccountMenu from "../components/AccountMenu";
import Faq from "../components/FAQ";
import FlashLinks from "../components/FlashLinks";
import ExternalRedirect from "../components/ExternalRedirect";
import ExternalEventRedirect from "../components/ExternalEventRedirect";

const AnimatedRoutes = () => {

    const location = useLocation();

    // Lazy load the split bundles
    const OrganizationsRoutes = lazy(() => import('./OrganizationsRoutes'));
    const MenuRoutes = lazy(() => import('./MenuRoutes'));

    const EventPreviewIdDark = () => {
        let {eventId, promoterId} = useParams();
        return(
            <ThemeProvider theme={darkTheme}>
                <EventPreviewDark event={eventId} promoter={promoterId} />
            </ThemeProvider>
        );
    }

    const EventPreviewWrapper = () => {
        const { eventId, promoterId } = useParams();

        return (
            <ThemeProvider theme={darkTheme}>
                <EventPreviewDark event={eventId} promoter={promoterId} />
            </ThemeProvider>
        );
    };

    const TicketPreviewId = () => {
        let {id} = useParams();
        return(
            <RenderTicket theme={darkTheme} ticket={id} />
        );
    }

    const ResetPasswordId = () => {
        let { token } = useParams();
        return <ResetPassword token={token} theme={darkTheme} />;
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        }
    });

    const FlashLinkId = () => {
        let {linkId} = useParams();
        return(
            <FlashLinks id={linkId} />
        );
    }

    const OrderId = () => {
        let {id} = useParams();
        return(
            <MenuOrder successPurchase={true} order={id} />
        );
    };

    return (
        <AnimatePresence>
            <Suspense>
                <Routes location={location} key={location.pathname}>

                    {
                        //<Route path="organizations/create" element={<CreateOrganization />} />
                    }

                    <Route path="/organizations/*" element={<OrganizationsRoutes />} />

                    <Route path="/menu/*" element={<MenuRoutes />} />
                    <Route exact path="/order/:id" element={<OrderId />} />
                    <Route exact path="/my-account" element={<AccountMenu />} />

                    <Route path="/google_auth" element={<GoogleAuthenticate />} />

                    <Route exact path="/links/:linkId" element={<FlashLinkId />} />

                    <Route path="/event/:eventId" element={<ExternalEventRedirect />} />
                    <Route path="/event/:eventId/:promoterId" element={<ExternalEventRedirect />} />

                    <Route exact path="/tickets" element={<ExternalRedirect to={`${process.env.REACT_APP_NEXT_PUBLIC_URL}/mis-tickets`} />} />

                    <Route path="/login" element={<ExternalRedirect to={`${process.env.REACT_APP_NEXT_PUBLIC_URL}/login`} />} />

                    {/*

                    <Route exact path="/" element={<HomePageUsersDark theme={darkTheme} />} />

                    <Route exact path="/event/:eventId" element={<EventPreviewIdDark />} />
                    <Route path="/event/:eventId/:promoterId" element={<EventPreviewIdDark />} />

                    <Route exact path="/links/:linkId" element={<FlashLinkId />} />

                    <Route path="/success-order" element={<SuccessOrder />} />

                    <Route exact path="/tickets" element={<MyTickets theme={darkTheme} />} />
                    <Route exact path="/ticket/:id" element={<TicketPreviewId theme={darkTheme} />} />

                    <Route exact path="/my-profile" element={<ProfileUsers theme={darkTheme} />} />
                    <Route exact path="/register" element={<CreateAccount theme={darkTheme} />} />
                    <Route exact path="/forgot-password" element={<ForgotPassword theme={darkTheme} />} />
                    <Route exact path="/password-reset/:token" element={<ResetPasswordId />} />

                    <Route exact path="/faq" element={<Faq theme={darkTheme} />} />

                    <Route path="/login" element={<Login theme={darkTheme} />} />

                    */}

                    <Route path="*" element={<ExternalRedirect to={process.env.REACT_APP_NEXT_PUBLIC_URL} />} />


                </Routes>
            </Suspense>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;