import React, {useEffect, useState, useRef} from 'react';
import '../styles/EventPreviewDark.css';
import {
    Alert,
    Button, CircularProgress, ClickAwayListener,
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField, Typography, Tooltip, FormHelperText
} from "@mui/material";
import {IoClose} from "react-icons/io5";
import {FaRegCalendarAlt} from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import {BsPersonPlus, BsTrash} from "react-icons/bs";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import EventsSwiper from "../components/EventsSwiper";
import NavbarHomePage from "../components/NavbarHomePage";
import {useLocation, useNavigate} from "react-router";
import Chip from "@mui/material/Chip";
import Footer from "../components/Footer";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {money_format, number_format} from "../components/functions/numberFormat";
import {Helmet} from "react-helmet-async";
import InfoIcon from '@mui/icons-material/Info';
import {GiCardExchange} from "react-icons/gi";
import {HiOutlineLocationMarker} from "react-icons/hi";
import {BiBuildingHouse} from "react-icons/bi";
import MyMap, {GoogleMap} from "../components/GoogleMapComponent";
import {styled} from "@mui/system";
import {loadPixel} from "../components/functions/useMetaPixel";
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import IconButton from "@mui/material/IconButton";
import {fetchWithAuth} from "../api";


const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '10px',
    },
}));

const EventPreview = (props) => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const track = query.get('track');

    const errorsInitialized = useRef(false);

    const [metaPixel, setMetaPixel] = useState(null);

    const [selectedTickets, setSelectedTickets] = useState([]);
    const [selectedCombos, setSelectedCombos] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [combos, setCombos] = useState([]);
    const [event, setEvent] = useState({id: "", name: "", description: "", date_start: "", date_end: "", location: "", limitage: "", show_related_events: 1, request_birth_date: 0 });
    const [relatedEvents, setRelatedEvents] = useState([]);
    const [producerRelatedEvents, setProducerRelatedEvents] = useState(false);
    const [trackId, setTrackId] = useState(track);
    const [resaleLink, setResaleLink] = useState("");

    const [USDMEPRate, setUSDMEPRate] = useState(null);


    const [fp_fee, setFp_fee] = useState(0.15);

    const [values, setValues] = useState({
        buyerData: {
            name: '',
            surname: '',
            email: '',
            confirmEmail: '',
            phone: '',
            documentNumber: '',
            province: '',
            birthDate: '',
            gender: '',
        },
        ticketsData: [],
        combosData: [],
        paymentMethod: 'payway',
    });

    const [errors, setErrors] = useState({
        buyerData: {
            name: false,
            surname: false,
            email: false,
            confirmEmail: false,
            phone: false,
            documentNumber: false,
            province: false,
            birthDate: false, // Added this line
        },
        ticketsData: [],
        combosData: [],
    });

    const [errorMessages, setErrorMessages] = useState({
        buyerData: {
            name: '',
            surname: '',
            email: '',
            confirmEmail: '',
            phone: '',
            documentNumber: '',
            province: '',
            birthDate: '', // Added this line
        },
        ticketsData: [],
        combosData: [],
    });

    const inputValidation = (
        e,
        prop,
        type,
        index,
        comboIndex,
        ticketIndex,
        variantCategoryIndex
    ) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        // Validation logic
        switch (prop) {
            case 'name':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'surname':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El apellido debe tener al menos 2 caracteres';
                }
                break;
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                } else if (values.buyerData.confirmEmail && value !== values.buyerData.confirmEmail) {
                    // Also update confirmEmail error state when email changes
                    setErrors(prev => ({
                        ...prev,
                        buyerData: {
                            ...prev.buyerData,
                            confirmEmail: true
                        }
                    }));
                    setErrorMessages(prev => ({
                        ...prev,
                        buyerData: {
                            ...prev.buyerData,
                            confirmEmail: 'Los emails no coinciden'
                        }
                    }));
                } else if (values.buyerData.confirmEmail && value === values.buyerData.confirmEmail) {
                    // Clear confirmEmail error when emails match
                    setErrors(prev => ({
                        ...prev,
                        buyerData: {
                            ...prev.buyerData,
                            confirmEmail: false
                        }
                    }));
                    setErrorMessages(prev => ({
                        ...prev,
                        buyerData: {
                            ...prev.buyerData,
                            confirmEmail: ''
                        }
                    }));
                }
                break;

            case 'confirmEmail':
                if (values.buyerData.email && value !== values.buyerData.email) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'documentNumber':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
                break;
            case 'province':
                if (value === '') {
                    error = true;
                    errorMessage = 'Debe seleccionar una provincia';
                }
                break;
            case 'ticketName':
                if (value.trim().length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'ticketDni':
                if (!/^[0-9]{7,8}$/.test(value)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
                break;
            case 'ticketGender':
                if (value === '') {
                    error = true;
                    errorMessage = 'Debe seleccionar un género';
                }
                break;
            case 'variants':
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    error = true;
                    errorMessage = 'Debe seleccionar al menos una opción';
                }
                break;
            default:
                break;
        }

        if (!value) {
            error = false;
            errorMessage = '';
        }

        if (type === 'buyerData') {
            setErrors({...errors, buyerData: {...errors.buyerData, [prop]: error}});
            setErrorMessages({...errorMessages, buyerData: {...errorMessages.buyerData, [prop]: errorMessage}});
        } else if (type === 'ticketsData') {
            // Update errors and errorMessages for ticketsData
            setErrors((prevErrors) => {
                const updatedTicketsData = prevErrors.ticketsData.map((ticketError, idx) =>
                    idx === index
                        ? {
                            ...ticketError,
                            [prop]: error,
                        }
                        : ticketError
                );
                return { ...prevErrors, ticketsData: updatedTicketsData };
            });

            setErrorMessages((prevErrorMessages) => {
                const updatedTicketsData = prevErrorMessages.ticketsData.map(
                    (ticketErrorMsg, idx) =>
                        idx === index
                            ? {
                                ...ticketErrorMsg,
                                [prop]: errorMessage,
                            }
                            : ticketErrorMsg
                );
                return { ...prevErrorMessages, ticketsData: updatedTicketsData };
            });
        } else if (type === 'combosDataTickets') {
            // Update errors and errorMessages for combosData tickets
            setErrors((prevErrors) => {
                const updatedCombosData = prevErrors.combosData.map((comboError, cIdx) =>
                    cIdx === comboIndex
                        ? {
                            ...comboError,
                            tickets: comboError.tickets.map((ticketError, tIdx) =>
                                tIdx === ticketIndex
                                    ? {
                                        ...ticketError,
                                        [prop]: error,
                                    }
                                    : ticketError
                            ),
                            products: comboError.products,
                        }
                        : comboError
                );
                return { ...prevErrors, combosData: updatedCombosData };
            });

            setErrorMessages((prevErrorMessages) => {
                const updatedCombosData = prevErrorMessages.combosData.map(
                    (comboErrorMsg, cIdx) =>
                        cIdx === comboIndex
                            ? {
                                ...comboErrorMsg,
                                tickets: comboErrorMsg.tickets.map((ticketErrorMsg, tIdx) =>
                                    tIdx === ticketIndex
                                        ? {
                                            ...ticketErrorMsg,
                                            [prop]: errorMessage,
                                        }
                                        : ticketErrorMsg
                                ),
                                products: comboErrorMsg.products,
                            }
                            : comboErrorMsg
                );
                return { ...prevErrorMessages, combosData: updatedCombosData };
            });
        } else if (type === 'combosDataProducts') {
            // Update errors and errorMessages for combosData products
            setErrors((prevErrors) => {
                const updatedCombosData = prevErrors.combosData.map((comboError, cIdx) =>
                    cIdx === comboIndex
                        ? {
                            ...comboError,
                            products: comboError.products.map((productError, pIdx) =>
                                pIdx === index
                                    ? {
                                        ...productError,
                                        variantCategories: productError.variantCategories.map(
                                            (variantCategoryError, vIdx) =>
                                                vIdx === variantCategoryIndex
                                                    ? {
                                                        ...variantCategoryError,
                                                        [prop]: error,
                                                    }
                                                    : variantCategoryError
                                        ),
                                    }
                                    : productError
                            ),
                            tickets: comboError.tickets,
                        }
                        : comboError
                );
                return { ...prevErrors, combosData: updatedCombosData };
            });

            setErrorMessages((prevErrorMessages) => {
                const updatedCombosData = prevErrorMessages.combosData.map(
                    (comboErrorMsg, cIdx) =>
                        cIdx === comboIndex
                            ? {
                                ...comboErrorMsg,
                                products: comboErrorMsg.products.map((productErrorMsg, pIdx) =>
                                    pIdx === index
                                        ? {
                                            ...productErrorMsg,
                                            variantCategories: productErrorMsg.variantCategories.map(
                                                (variantCategoryErrorMsg, vIdx) =>
                                                    vIdx === variantCategoryIndex
                                                        ? {
                                                            ...variantCategoryErrorMsg,
                                                            [prop]: errorMessage,
                                                        }
                                                        : variantCategoryErrorMsg
                                            ),
                                        }
                                        : productErrorMsg
                                ),
                                tickets: comboErrorMsg.tickets,
                            }
                            : comboErrorMsg
                );
                return { ...prevErrorMessages, combosData: updatedCombosData };
            });
        }
    };

    const steps = [
        'Revisá tu orden',
        'Datos del comprador',
        'Datos de los tickets',
        'Confirmación',
    ];

    const ticketsGenderOptions = [
        {
            id: 'M',
            label: 'Hombre'
        },
        {
            id: 'F',
            label: 'Mujer'
        }
    ]

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (e) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const submitForm = (e) => {
        e.preventDefault();
        const requiredFields = [
            'name',
            'surname',
            'email',
            'confirmEmail',
            'phone',
            'documentNumber',
            'province',
        ];
        if (event.request_birth_date === 1) {
            requiredFields.push('birthDate');
        }

        const errorCheck = requiredFields.some((field) => errors.buyerData[field] === true);
        const requiredCheck = requiredFields.some((field) => {
            if (field === 'birthDate') {
                return !values.buyerData.birthDate;
            } else {
                return values.buyerData[field] === '';
            }
        });

        if (!errorCheck && !requiredCheck) {
            handleNext();
        } else {
            setDisabledButton(true);
        }
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleBuyerChange = (prop) => (event) => {
        const newValue = event.target.value;
        setValues({
            ...values,
            buyerData: { ...values.buyerData, [prop]: newValue },
        });

        // Trigger validation for the changed field
        inputValidation(event, prop, 'buyerData');

        // If email or confirmEmail changes, validate the other field as well
        if (prop === 'email' && values.buyerData.confirmEmail) {
            inputValidation(
                { target: { value: values.buyerData.confirmEmail } },
                'confirmEmail',
                'buyerData'
            );
        } else if (prop === 'confirmEmail' && values.buyerData.email) {
            inputValidation(
                { target: { value: values.buyerData.email } },
                'email',
                'buyerData'
            );
        }
    };

    const handleTicketChange = (prop, index) => (event) => {
        const updatedTicketsData = [...values.ticketsData];
        updatedTicketsData[index][prop] = event.target.value;
        setValues({
            ...values,
            ticketsData: updatedTicketsData,
        });
    };

    const handleSelectTickets = (event) => {
        const ticketId = parseInt(event.target.id);
        const newQuantity = parseInt(event.target.value);

        // Validate newQuantity
        if (isNaN(newQuantity) || newQuantity < 0) {
            console.error('Invalid quantity');
            return;
        }

        // Find all existing tickets with this ticketId
        const existingTickets = values.ticketsData.filter(
            (ticketData) => ticketData.eventTicketId === ticketId
        );

        const existingQuantity = existingTickets.length;

        const selectedTicket = tickets.find((tick) => tick.id === ticketId);
        const ticketCombo = selectedTicket.combo || 1;

        const totalNewTicketsNeeded = newQuantity * ticketCombo;

        let updatedTicketsData = [...values.ticketsData];

        if (totalNewTicketsNeeded > existingQuantity) {
            // Need to add new tickets
            const ticketsToAdd = totalNewTicketsNeeded - existingQuantity;
            const newTickets = Array.from({ length: ticketsToAdd }, () => ({
                eventTicketId: ticketId,
                ticketPrice: Number(selectedTicket.price) / Number(ticketCombo),
                ticketName: '',
                ticketDni: '',
                ticketGender: '',
            }));
            updatedTicketsData = [...updatedTicketsData, ...newTickets];
        } else if (totalNewTicketsNeeded < existingQuantity) {
            // Need to remove tickets from the end
            let ticketsToRemove = existingQuantity - totalNewTicketsNeeded;
            updatedTicketsData = updatedTicketsData.filter((ticketData) => {
                if (
                    ticketData.eventTicketId === ticketId &&
                    ticketsToRemove > 0
                ) {
                    ticketsToRemove--;
                    return false; // Remove this ticket
                }
                return true; // Keep this ticket
            });
        }
        // Else, quantities are equal, do nothing

        // **Pre-fill first ticket with buyer's data**
        if (updatedTicketsData.length > 0) {
            const firstTicket = updatedTicketsData[0];
            if (
                !firstTicket.ticketName &&
                !firstTicket.ticketDni &&
                !firstTicket.ticketGender
            ) {
                // Pre-fill with buyer's data
                updatedTicketsData[0] = {
                    ...firstTicket,
                    ticketName: `${values.buyerData.name} ${values.buyerData.surname}`,
                    ticketDni: values.buyerData.documentNumber,
                    ticketGender: values.buyerData.gender || '', // Assuming buyerData includes gender
                };
            }
        }

        // Update the state with the updated ticketsData
        setValues({
            ...values,
            ticketsData: updatedTicketsData,
        });

        if (activeStep > 2) {
            setActiveStep(2);
        }
    };



    const handleSelectCombos = (combo) => {
        if (!combo.target.id) {
            return;
        }

        const comboId = parseInt(combo.target.id);
        const newQuantity = parseInt(combo.target.value);

        // Validate newQuantity
        if (isNaN(newQuantity) || newQuantity < 0) {
            console.error('Invalid quantity');
            return;
        }

        const selectedCombo = combos.find(com => com.id === comboId);

        if (!selectedCombo) {
            console.error('Combo not found');
            return;
        }

        // Find the index of the existing combo in combosData
        const existingComboIndex = values.combosData.findIndex(comboData => comboData.comboId === comboId);

        // Create a copy of combosData to modify
        let updatedCombosData = [...values.combosData];

        if (newQuantity === 0) {
            // Remove the combo from combosData if it exists
            if (existingComboIndex >= 0) {
                updatedCombosData.splice(existingComboIndex, 1);
            }
            // Remove from selectedCombos
            setSelectedCombos(selectedCombos.filter(id => id !== comboId));
        } else {
            // Generate updated tickets and products based on the new quantity
            const comboTickets = selectedCombo.tickets.flatMap(ticket => {
                const totalQuantity = ticket.quantity * newQuantity;
                return Array.from({ length: totalQuantity }, () => ({
                    eventTicketId: parseInt(ticket.eventTicketId),
                    ticketPrice: 0,
                    ticketName: '',
                    ticketDni: '',
                    ticketGender: '',
                }));
            });

            const comboProducts = selectedCombo.products.flatMap(product => {
                const totalQuantity = product.quantity * newQuantity;
                return Array.from({ length: totalQuantity }, () => ({
                    productId: product.productId,
                    productPrice: 0,
                    productName: product.productName,
                    variantCategories: product.variantCategories.map(category => ({
                        categoryId: category.id,
                        categoryName: category.title,
                        variants: [], // Empty array for all categories
                    })),
                }));
            });

            const comboData = {
                comboId: comboId,
                comboPrice: selectedCombo.price,
                comboName: selectedCombo.name,
                tickets: comboTickets,
                products: comboProducts,
                variants: selectedCombo.variants,
                quantity: newQuantity,
                balances: selectedCombo.balance
            };

            if (existingComboIndex >= 0) {
                // Update the existing combo in combosData
                updatedCombosData[existingComboIndex] = comboData;
            } else {
                // Add the new combo to combosData
                updatedCombosData.push(comboData);
            }

            // Add to selectedCombos if not already present
            if (!selectedCombos.includes(comboId)) {
                setSelectedCombos([...selectedCombos, comboId]);
            }
        }

        // Update the state with the updated combosData
        setValues({
            ...values,
            combosData: updatedCombosData,
        });

        if (activeStep > 2) {
            setActiveStep(2);
        }
    };

    const addTicket = (ticketId, combo) => {
        const selectedTicket = tickets.find((t) => t.id === parseInt(ticketId));
        const ticketCombo = combo || 1;

        const newTickets = Array.from({ length: ticketCombo }, () => ({
            eventTicketId: parseInt(ticketId),
            ticketPrice: Number(selectedTicket.price) / Number(ticketCombo),
            ticketName: '',
            ticketDni: '',
            ticketGender: '',
        }));

        setValues({
            ...values,
            ticketsData: [...values.ticketsData, ...newTickets],
        });

        if (activeStep > 2) {
            setActiveStep(2);
        }
    };

    const reduceTicket = (ticketId, combo) => {
        const ticketCombo = combo || 1;

        let count = 0;
        const updatedTicketsData = values.ticketsData.filter((ticketData) => {
            if (
                ticketData.eventTicketId === parseInt(ticketId) &&
                count < ticketCombo
            ) {
                count++;
                return false; // Remove this ticket
            }
            return true; // Keep this ticket
        });

        setValues({
            ...values,
            ticketsData: updatedTicketsData,
        });
    };

    const fetchEventData = async () => {
        //const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/event?event_id=${props.event}${props.promoter ? `&promoter_id=${props.promoter}` : ""}`, {
        const request = await fetchWithAuth(`${process.env.REACT_APP_API_V2_URL}/event/${props.event}${props.promoter ? `/${props.promoter}` : ""}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        if (request.status === 404) {
            navigate('/');
            return;
        }

        const response = await request.json();

        if (response.success === "1") {
            setEvent(response.event);

            const combos = response.combos.map(combo => {
                return {
                    ...combo,
                    type: 'combo',
                }
            });
            setCombos(combos);

            const tickets = response.tickets.map(ticket => ({...ticket, type: 'ticket'}));
            setTickets(tickets);

            setFp_fee(response.fp_fee);
            setMetaPixel(response.event.meta_pixel);
            setResaleLink(response.flashtrade_link);

            if (response.event.status === 3) {
                navigate('/');
            }
        }
    }

    const fetchProvinces = async () => {
        const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/provinces?country=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
        });

        const response = await request.json();
        setProvinceList(response);
    }

    const fetchRelatedEvents = async () => {
        const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/related-events?event=${props.event}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
        });
        const response = await request.json();

        if (response.status === "success") {
            setRelatedEvents(response.related);
            if (response.producer_related) {
                setProducerRelatedEvents(response.producer_related);
            }
        }
    }

    const fetchBuyer = async () => {
        if (user.token) {
            const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/buyer-data?event=${props.event}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${user.token}`,
                    'credentials': 'same-origin',
                },
            });
            const response = await request.json();
            const buyerData = {
                ...response,
                documentNumber: response?.dni,
                birthDate:
                    response?.birthDate && response.birthDate !== '0000-00-00'
                        ? new Date(response.birthDate)
                        : null,
            };

            setValues({...values, buyerData: buyerData});

        }
    }

    const fetchData = async () => {
        await Promise.all([
            fetchEventData(),
            fetchProvinces(),
            fetchBuyer(),
            fetchRelatedEvents(),
        ]);
        setIsLoaded(true);
    }

    const checkoutV2 = async () => {
        window.scrollTo(0, 0);
        setOpen(false);
        window.scrollTo(0, 0);
        setIsLoaded(false);
        const request = await fetchWithAuth(`${process.env.REACT_APP_API_V2_URL}/tickets/checkout`, {
        method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "buyerData": {...values.buyerData},
                "combos": values.combosData,
                "tickets": values.ticketsData,
                "promoter": event.promoter,
                "event": event.id,
                "track": trackId,
                "paymentMethod": values.paymentMethod,
                "event_single_link": event?.event_link,
                "total": number_format(totalPrice * (1 + fp_fee), 2, '.', ''),
            })
        });

        const response = await request.text();

        if (request.status === 201 || request.status === 200) {
            window.location.href = response;
        }
    }

    const checkoutV1 = async () => {
        window.scrollTo(0, 0);
        setOpen(false);
        window.scrollTo(0, 0);
        setIsLoaded(false);

        for (const key in values.ticketsData) {
            const ticket = values.ticketsData[key];
            ticket.ticketDNI = ticket.ticketDni;
            ticket.ticketId = ticket.eventTicketId;
        }

        const request = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/checkout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
                'credentials': 'same-origin',
            },
            body: JSON.stringify({
                "buyerData": {...values.buyerData, dni: values.buyerData.documentNumber},
                "combos": values.combosData,
                "tickets": values.ticketsData,
                "promoter": event.promoter,
                "event": event.id,
                "track_id": trackId,
                "paymentMethod": values.paymentMethod,
                "event_single_link": event?.event_link,
                "total": number_format(totalPrice * (1 + fp_fee), 2, '.', ''),
            })
        });

        const response = await request.text();

        if (request.status === 200) {
            window.location.href = response;
        }
    }

    const checkout = async () => {
        // i want the function to try with checkoutV2 and if it fails, try with checkoutV1
        try {
            await checkoutV2();
        } catch (error) {
            console.error(error);
            //await checkoutV1();
        }
    }

    const addCombo = (comboId) => {
        // Find the existing combo in combosData
        const existingCombo = values.combosData.find(combo => combo.comboId === comboId);
        // Determine the new quantity
        const newQuantity = existingCombo ? existingCombo.quantity + 1 : 1;
        // Call handleSelectCombos with the new quantity
        handleSelectCombos({ target: { id: comboId.toString(), value: newQuantity.toString() } });
    };

    const reduceCombo = (comboId) => {
        // Decrease the quantity of the selected combo
        const existingCombo = values.combosData.find((combo) => combo.comboId === comboId);
        if (existingCombo.quantity > 1) {
            handleSelectCombos({ target: { id: comboId.toString(), value: (existingCombo.quantity - 1).toString() } });
        } else {
            removeCombo(comboId);
        }
    };

    const removeCombo = (comboId) => {
        // Set the quantity to zero to remove the combo
        handleSelectCombos({ target: { id: comboId.toString(), value: "0" } });
    };

    const handleProductChange = (field, comboIndex, productIndex) => (event) => {
        const updatedCombosData = [...values.combosData];
        updatedCombosData[comboIndex].products[productIndex][field] = event.target.value;
        setValues({ ...values, combosData: updatedCombosData });
    };

    const handleComboTicketChange = (field, comboIndex, ticketIndex) => (event) => {
        const updatedCombosData = [...values.combosData];
        updatedCombosData[comboIndex].tickets[ticketIndex][field] = event.target.value;
        setValues({ ...values, combosData: updatedCombosData });

        // Call inputValidation
        inputValidation(
            event,
            field,
            'combosDataTickets',
            null,
            comboIndex,
            ticketIndex
        );
    };

    const handleProductVariantChange = (field, comboIndex, productIndex, variantCategoryIndex, isUnique) => (event) => {
        const updatedCombosData = [...values.combosData];
        const variantCategory =
            updatedCombosData[comboIndex].products[productIndex].variantCategories[variantCategoryIndex];

        let value = event.target.value;

        if (isUnique) {
            // Wrap the single value in an array
            variantCategory[field] = [value];
        } else {
            // Ensure value is always an array
            variantCategory[field] = Array.isArray(value) ? value : [];
        }

        setValues({ ...values, combosData: updatedCombosData });
    };

    // Compute total price for selected tickets
    const totalTicketPrice = values.ticketsData
        .map((ticketData) => ticketData.ticketPrice)
        .reduce((a, b) => a + b, 0);

    // Compute total price for selected combos
    const totalComboPrice = values.combosData
        .map(combo => combo.comboPrice * combo.quantity)
        .reduce((a, b) => a + b, 0);

    // Sum both totals
    const totalPrice = totalTicketPrice + totalComboPrice;

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);

    useEffect(() => {
        if (activeStep !== 2) {
            errorsInitialized.current = false;
        }
    }, [activeStep]);

    useEffect(() => {
        if (activeStep === 1) {
            const requiredFields = [
                'name',
                'surname',
                'email',
                'confirmEmail',
                'phone',
                'documentNumber',
                'province',
            ];
            if (event.request_birth_date === 1) {
                requiredFields.push('birthDate');
            }

            const errorCheck = requiredFields.some(
                (field) => errors.buyerData[field] === true
            );
            const requiredCheck = requiredFields.some((field) => {
                if (field === 'birthDate') {
                    return (
                        !values.buyerData.birthDate ||
                        isNaN(new Date(values.buyerData.birthDate).getTime())
                    );
                } else {
                    return values.buyerData[field] === '';
                }
            });

            if (!errorCheck && !requiredCheck) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
    }, [values.buyerData, errors.buyerData, activeStep, event.request_birth_date]);

    useEffect(() => {
        if (values.ticketsData.length > 0) {
            setValues((prevValues) => {
                const updatedTicketsData = [...prevValues.ticketsData];
                updatedTicketsData[0] = {
                    ...updatedTicketsData[0],
                    ticketName: `${prevValues.buyerData.name} ${prevValues.buyerData.surname}`,
                    ticketDni: prevValues.buyerData.documentNumber,
                    ticketGender: prevValues.buyerData.gender || '',
                };
                return {
                    ...prevValues,
                    ticketsData: updatedTicketsData,
                };
            });
        }
    }, [values.buyerData]);


    useEffect(() => {
        let ticketsErrorCheck = false;
        let ticketsRequiredCheck = false;
        let combosErrorCheck = false;
        let combosRequiredCheck = false;

        if (activeStep === 2) {
            // Check if there are tickets or combos selected
            const hasTickets = values.ticketsData && values.ticketsData.length > 0;
            const hasCombos = values.combosData && values.combosData.length > 0;

            if (!hasTickets && !hasCombos) {
                // No tickets or combos selected, disable the button
                setDisabledButton(true);
                return;
            }

            // Validate ticketsData
            if (hasTickets) {
                for (let i = 0; i < values.ticketsData.length; i++) {
                    const ticketData = values.ticketsData[i];
                    const ticketId = ticketData.eventTicketId;
                    const ticket = tickets.find((t) => t.id === ticketId);
                    const genderRequired = ticket?.buyer_declared_gender === 1;

                    const fieldsToCheck = ['ticketName', 'ticketDni'];
                    if (genderRequired) {
                        fieldsToCheck.push('ticketGender');
                    }

                    const hasError = fieldsToCheck.some(
                        (field) => errors.ticketsData[i]?.[field] === true
                    );
                    const isRequiredMissing = fieldsToCheck.some(
                        (field) => !ticketData[field] || ticketData[field] === ''
                    );

                    if (hasError) {
                        ticketsErrorCheck = true;
                    }
                    if (isRequiredMissing) {
                        ticketsRequiredCheck = true;
                    }
                }
            }

            // Validate combosData
            if (hasCombos) {
                for (let comboIndex = 0; comboIndex < values.combosData.length; comboIndex++) {
                    const combo = values.combosData[comboIndex];

                    // Validate combo tickets
                    if (combo.tickets && combo.tickets.length > 0) {
                        for (let ticketIndex = 0; ticketIndex < combo.tickets.length; ticketIndex++) {
                            const ticket = combo.tickets[ticketIndex];
                            const ticketId = ticket.eventTicketId;
                            const eventTicket = tickets.find((t) => t.id === ticketId);
                            const genderRequired = eventTicket?.buyer_declared_gender === 1;

                            const fieldsToCheck = ['ticketName', 'ticketDni'];
                            if (genderRequired) {
                                fieldsToCheck.push('ticketGender');
                            }

                            const hasError = fieldsToCheck.some(
                                (field) =>
                                    errors.combosData?.[comboIndex]?.tickets?.[ticketIndex]?.[field] === true
                            );
                            const isRequiredMissing = fieldsToCheck.some(
                                (field) => !ticket[field] || ticket[field] === ''
                            );

                            if (hasError) {
                                combosErrorCheck = true;
                            }
                            if (isRequiredMissing) {
                                combosRequiredCheck = true;
                            }
                        }
                    }

                    // Validate combo products
                    if (combo.products && combo.products.length > 0) {
                        for (let productIndex = 0; productIndex < combo.products.length; productIndex++) {
                            const product = combo.products[productIndex];

                            if (product.variantCategories && product.variantCategories.length > 0) {
                                for (
                                    let variantCategoryIndex = 0;
                                    variantCategoryIndex < product.variantCategories.length;
                                    variantCategoryIndex++
                                ) {
                                    const variantCategory = product.variantCategories[variantCategoryIndex];

                                    const hasError =
                                        errors.combosData?.[comboIndex]?.products?.[productIndex]?.variantCategories?.[variantCategoryIndex]?.variants === true;
                                    const isRequiredMissing =
                                        !variantCategory.variants || variantCategory.variants.length === 0;

                                    if (hasError) {
                                        combosErrorCheck = true;
                                    }
                                    if (isRequiredMissing) {
                                        combosRequiredCheck = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            const totalErrorCheck = ticketsErrorCheck || combosErrorCheck;
            const totalRequiredCheck = ticketsRequiredCheck || combosRequiredCheck;

            setDisabledButton(totalErrorCheck || totalRequiredCheck);
        }
    }, [
        values.ticketsData,
        errors.ticketsData,
        values.combosData,
        errors.combosData,
        activeStep,
    ]);

    useEffect(() => {
        // Initialize errors and errorMessages for ticketsData when ticketsData changes
        setErrors((prevErrors) => ({
            ...prevErrors,
            ticketsData: values.ticketsData.map((_, index) => ({
                ticketName: prevErrors.ticketsData?.[index]?.ticketName || false,
                ticketDni: prevErrors.ticketsData?.[index]?.ticketDni || false,
                ticketGender: prevErrors.ticketsData?.[index]?.ticketGender || false,
            })),
        }));

        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            ticketsData: values.ticketsData.map((_, index) => ({
                ticketName: prevErrorMessages.ticketsData?.[index]?.ticketName || '',
                ticketDni: prevErrorMessages.ticketsData?.[index]?.ticketDni || '',
                ticketGender: prevErrorMessages.ticketsData?.[index]?.ticketGender || '',
            })),
        }));
    }, [values.ticketsData.length]);

    useEffect(() => {
        // Initialize errors and errorMessages for combosData when combosData changes
        setErrors((prevErrors) => ({
            ...prevErrors,
            combosData: values.combosData.map((combo, comboIndex) => ({
                tickets: combo.tickets.map(() => ({
                    ticketName: false,
                    ticketDni: false,
                    ticketGender: false,
                })),
                products: combo.products.map((product) => ({
                    variantCategories: product.variantCategories.map(() => ({
                        variants: false,
                    })),
                })),
            })),
        }));

        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            combosData: values.combosData.map((combo, comboIndex) => ({
                tickets: combo.tickets.map(() => ({
                    ticketName: '',
                    ticketDni: '',
                    ticketGender: '',
                })),
                products: combo.products.map((product) => ({
                    variantCategories: product.variantCategories.map(() => ({
                        variants: '',
                    })),
                })),
            })),
        }));
    }, [values.combosData.length]);

    useEffect(()=>  {
        window.scrollTo(0, 0);
    },[]);

    useEffect(() => {
        loadPixel(metaPixel);
        // Fire a standard event, e.g., PageView
        window.fbq('track', 'PageView');
    }, [metaPixel]);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [openedByClick, setOpenedByClick] = useState(false);

    useEffect(() => {
        if (values.buyerData.email === values.buyerData.confirmEmail) {
            setErrors((prevErrors) => {
                const updatedBuyerData = prevErrors.buyerData;
                updatedBuyerData.confirmEmail = false;
                return { ...prevErrors, buyerData: updatedBuyerData };
            });

            setErrorMessages((prevErrorMessages) => {
                const updatedBuyerData = prevErrorMessages.buyerData;
                updatedBuyerData.confirmEmail = '';
                return { ...prevErrorMessages, buyerData: updatedBuyerData };
            });
        }
    }, [values.buyerData.email, values.buyerData.confirmEmail]);

    useEffect(() => {
        console.log(values.ticketsData)
    }, [values.ticketsData])

    useEffect(() => {
        console.log(tickets)
    }, [tickets])

    return (
        <div className="eventPreviewDarkPage">
            <Helmet>
                <meta property="og:image" content={event?.portada} />
                <meta property="og:title" content={event?.name} />
                <meta name="title" content={event?.name} />
                <meta name="description" content={event?.description} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://www.flashpass.com.ar/event/" + props.event} />
                <meta property="og:description" content={event?.description} />
                <title>{event?.name ? `${event?.name} - FlashPass` : `FlashPass`}</title>
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <NavbarHomePage page={"event"} />
            <div className="p-md-5 pt-3 pb-3" style={{backgroundColor: "#1a1a1a", marginTop: "60px"}}>
                <div className="container">
                    <div className="eventCardPreviewDark p-3 p-md-4 p-lg-5" style={{minHeight: "100vh"}}>
                        {!isLoaded ? (
                            <div style={{position: "absolute", top: "50%", left: "50%", transform: `translate(-50%, -50%)`}}>
                                <CircularProgress color="secondary" size={100} />
                            </div>
                        ) : (
                            <>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div style={{overflow: "hidden", borderRadius: ".5rem"}}>
                                            <img className="img-fluid" src={event?.portada}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 ps-lg-5">
                                        <div className="row">
                                            <div className="col-12 mt-5 mt-lg-0">
                                                <h2 className="d-flex align-items-center">{event?.name}{event?.status === 0 && <Chip label="FINALIZADO" color="error" className="ms-3" />}</h2>
                                                <h5 style={{opacity: "0.75"}}>{event?.producer}</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mt-3">
                                                <FaRegCalendarAlt/><span className="ms-2">Fecha</span>
                                                <Typography variant="body2" color="text"
                                                            className="mt-2 opacity-60">
                                                    {
                                                        event?.date_start
                                                    }
                                                </Typography>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <MdLocationOn />
                                                <span className="ms-2">Ubicación</span>
                                                <Typography
                                                    variant="body2"
                                                    color="text"
                                                    className="mt-2 opacity-60"
                                                    onClick={() => {
                                                        const element = document.getElementById("location-detail");
                                                        if (element) {
                                                            const rect = element.getBoundingClientRect();
                                                            const offsetTop = rect.top + window.pageYOffset;
                                                            window.scrollTo({
                                                                top: offsetTop - 70,
                                                                behavior: 'smooth'
                                                            });
                                                        }
                                                    }}
                                                    style={{cursor: "pointer", textDecoration: "underline"}}
                                                >
                                                    {event?.venue ? event?.venue : event?.location}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {event?.limitage && (
                                                <div className="col-md-6 mt-3">
                                                    <BsPersonPlus/><span className="ms-2">Edad mínima</span>
                                                    <Typography variant="body2" color="text"
                                                                className="mt-2 opacity-60">{event?.limitage} años</Typography>
                                                </div>
                                            )}
                                        </div>
                                        {event?.whitelist && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <hr className="mb-4 mt-4"/>
                                                    <Alert variant="outlined" severity="info">
                                                        El evento es exclusivo para miembros seleccionados por el Organizador. Si no eres miembro, no podrás adquirir tickets.
                                                    </Alert>
                                                </div>
                                            </div>
                                        )}
                                        {event?.status === 1 && (
                                            <>
                                                <hr className="mb-3 mt-4" style={{marginTop: 0}}/>
                                                {tickets.length > 0 ? (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <b>Tickets</b>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                <div className="eventTicketsDark">
                                                                    <table
                                                                        className="table table-borderless table-sm tableTicketsDark">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col"
                                                                                className="eventTicketColumn">Ticket
                                                                            </th>
                                                                            <th scope="col">Precio</th>
                                                                            <th scope="col">Cantidad</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {tickets.map((ticket, index) => (
                                                                            <tr key={index}>
                                                                                <td className="tableDesc eventTicketColumn"
                                                                                    style={{verticalAlign: "middle"}}>
                                                                                    <div>
                                                                                        {ticket.name}
                                                                                        <small className="d-block"
                                                                                               style={{
                                                                                                   opacity: "0.75",
                                                                                                   fontSize: "60%"
                                                                                               }}>{ticket.description}</small>
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{
                                                                                    verticalAlign: 'middle',
                                                                                    minWidth: '100px'
                                                                                }}>
                                                                                    {ticket.price > 0 ? (
                                                                                        ticket.currency === 'USD' ? (
                                                                                            event.service_charge ? (
                                                                                                <>
                                                                                                    <div
                                                                                                        className="priceTextTicketEvent">
                                                                                                        <p className="price-line"
                                                                                                           style={{
                                                                                                               whiteSpace: 'nowrap',
                                                                                                               overflowX: 'auto'
                                                                                                           }}>
                                                                                                            <strong>US$ {number_format(ticket.priceUSD, 2, ',', '.')}</strong>
                                                                                                            <span
                                                                                                                className="info-icon">
                                                                                                                <div>
                                                                                                                  <Tooltip
                                                                                                                      className="ms-0"
                                                                                                                      title="El valor expresado en dólares es únicamente de referencia."
                                                                                                                      placement="bottom"
                                                                                                                      enterTouchDelay={0}
                                                                                                                      leaveTouchDelay={5000}
                                                                                                                      enterDelay={500}
                                                                                                                  >
                                                                                                                    <IconButton>
                                                                                                                      <InfoIcon
                                                                                                                          style={{fontSize: '12px'}}/>
                                                                                                                    </IconButton>
                                                                                                                  </Tooltip>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                        </p>
                                                                                                        <p className="price-line">$ {number_format(ticket.price, 2, ',', '.')}</p>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    $
                                                                                                    {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                                </>
                                                                                            )
                                                                                        ) : event.service_charge ? (
                                                                                            <>
                                                                                                $
                                                                                                {number_format(ticket.price, 2, ',', '.')}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                $
                                                                                                {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                            </>
                                                                                        )
                                                                                    ) : (
                                                                                        'Gratuita'
                                                                                    )}
                                                                                </td>
                                                                                <td className="selectAmmount" style={{
                                                                                    verticalAlign: "middle",
                                                                                    minWidth: "115px"
                                                                                }}>
                                                                                    {ticket.available === 1 && (
                                                                                        <select
                                                                                            id={ticket.id}
                                                                                            value={
                                                                                                values.ticketsData.filter((t) => t.eventTicketId === ticket.id).length /
                                                                                                (ticket.combo || 1)
                                                                                            }
                                                                                            onChange={handleSelectTickets}
                                                                                            className="form-select"
                                                                                            style={{
                                                                                                maxWidth: '8rem',
                                                                                                minWidth: '4rem',
                                                                                            }}
                                                                                        >
                                                                                            <option key={0} value={0}>
                                                                                                0
                                                                                            </option>
                                                                                            {(() => {
                                                                                                const ticketCombo = ticket.combo || 1;
                                                                                                const maxAvailableQuantity = Math.floor(ticket.quantity / ticketCombo);
                                                                                                const maxSelectableQuantity = Math.min(maxAvailableQuantity, ticket.limit_per_order);

                                                                                                return Array.from({length: maxSelectableQuantity}, (_, i) => (
                                                                                                    <option key={i + 1}
                                                                                                            value={i + 1}>
                                                                                                        {i + 1}
                                                                                                    </option>
                                                                                                ));
                                                                                            })()}
                                                                                        </select>
                                                                                    )}
                                                                                    {ticket.available === 0 && ticket.show_soon === 0 && (
                                                                                        <Button className="w-100"
                                                                                                style={{
                                                                                                    maxWidth: "8rem",
                                                                                                    fontSize: "0.7rem",
                                                                                                    //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                                }} color="error"
                                                                                                variant="contained"
                                                                                                disabled>
                                                                                            Agotado
                                                                                        </Button>
                                                                                    )}
                                                                                    {ticket.available === 0 && ticket.show_soon === 1 && (
                                                                                        <Button className="w-100"
                                                                                                style={{
                                                                                                    maxWidth: "8rem",
                                                                                                    fontSize: "0.55rem",
                                                                                                    //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                                }} color="info"
                                                                                                variant="contained"
                                                                                                disabled>
                                                                                            Próximamente
                                                                                        </Button>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {combos.length > 0 ? (
                                                                <>
                                                                    <div className="row mt-3">
                                                                        <div className="col-12">
                                                                            <b>Combos</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className="col-12">
                                                                            <div className="eventTicketsDark">
                                                                                <table
                                                                                    className="table table-borderless table-sm tableTicketsDark">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th scope="col"
                                                                                            className="eventTicketColumn">Combo
                                                                                        </th>
                                                                                        <th scope="col">Precio</th>
                                                                                        <th scope="col">Cantidad</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {combos.map((ticket, index) => (
                                                                                        <tr key={index}>
                                                                                            <td className="tableDesc eventTicketColumn"
                                                                                                style={{verticalAlign: "middle"}}>
                                                                                                <div>
                                                                                                    {ticket.name}
                                                                                                    <small
                                                                                                        className="d-block"
                                                                                                        style={{
                                                                                                            opacity: "0.75",
                                                                                                            fontSize: "60%"
                                                                                                        }}>{ticket.description}</small>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td style={{
                                                                                                verticalAlign: 'middle',
                                                                                                minWidth: '100px'
                                                                                            }}>
                                                                                                {ticket.price > 0 ? (
                                                                                                    ticket.currency === 'USD' ? (
                                                                                                        event.service_charge ? (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className="priceTextTicketEvent">
                                                                                                                    <p className="price-line"
                                                                                                                       style={{
                                                                                                                           whiteSpace: 'nowrap',
                                                                                                                           overflowX: 'auto'
                                                                                                                       }}>
                                                                                                                        <strong>US$ {number_format(ticket.priceUsd, 2, ',', '.')}</strong>
                                                                                                                        <span
                                                                                                                            className="info-icon">
                                                                                                                <div>
                                                                                                                  <Tooltip
                                                                                                                      className="ms-0"
                                                                                                                      title="El valor expresado en dólares es únicamente de referencia."
                                                                                                                      placement="bottom"
                                                                                                                      enterTouchDelay={0}
                                                                                                                      leaveTouchDelay={5000}
                                                                                                                      enterDelay={500}
                                                                                                                  >
                                                                                                                    <IconButton>
                                                                                                                      <InfoIcon
                                                                                                                          style={{fontSize: '12px'}}/>
                                                                                                                    </IconButton>
                                                                                                                  </Tooltip>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                                    </p>
                                                                                                                    <p className="price-line">$ {number_format(ticket.price, 2, ',', '.')}</p>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                $
                                                                                                                {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                                            </>
                                                                                                        )
                                                                                                    ) : event.service_charge ? (
                                                                                                        <>
                                                                                                            <div
                                                                                                                className="priceTextTicketEvent">
                                                                                                                <p className="price-line"
                                                                                                                   style={{
                                                                                                                       whiteSpace: 'nowrap',
                                                                                                                       overflowX: 'auto'
                                                                                                                   }}>
                                                                                                                    $ {number_format(ticket.price, 2, ',', '.')}
                                                                                                                    <span
                                                                                                                        className="info-icon">
                                                                                                                <div>
                                                                                                                  <Tooltip
                                                                                                                      className="ms-0"
                                                                                                                      title={
                                                                                                                          <React.Fragment>
                                                                                                                              <div style={{ textAlign: 'left' }}>
                                                                                                                                  <strong>El combo incluye:</strong>
                                                                                                                                  {ticket.tickets.map((subTicket, index) => (
                                                                                                                                        <React.Fragment key={index}>
                                                                                                                                            <p className="mt-2 mb-0">- {subTicket.quantity}x {subTicket.eventTicketName}</p>
                                                                                                                                        </React.Fragment>
                                                                                                                                  ))}
                                                                                                                                  {ticket.products.map((product, index) => (
                                                                                                                                        <React.Fragment key={index}>
                                                                                                                                            <p className="mt-2 mb-0">- {product.quantity}x {product.productName}</p>
                                                                                                                                        </React.Fragment>
                                                                                                                                  ))}
                                                                                                                                  {ticket.balance.length > 0 && ticket.balance.map((balance, index) => (
                                                                                                                                        <React.Fragment key={index}>
                                                                                                                                            <p className="mt-2 mb-0">- {money_format(balance.amount, 2, ',', '.')} para consumir en el evento.</p>
                                                                                                                                        </React.Fragment>
                                                                                                                                      ))
                                                                                                                                  }
                                                                                                                              </div>
                                                                                                                          </React.Fragment>
                                                                                                                      }
                                                                                                                      placement="bottom"
                                                                                                                      enterTouchDelay={0}
                                                                                                                      leaveTouchDelay={5000}
                                                                                                                      enterDelay={500}
                                                                                                                  >
                                                                                                                    <IconButton>
                                                                                                                      <InfoIcon
                                                                                                                          style={{fontSize: '12px'}}/>
                                                                                                                    </IconButton>
                                                                                                                  </Tooltip>
                                                                                                                </div>
                                                                                                            </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            $
                                                                                                            {number_format((1 + fp_fee) * ticket.price, 2, ',', '.')}
                                                                                                        </>
                                                                                                    )
                                                                                                ) : (
                                                                                                    'Gratuita'
                                                                                                )}
                                                                                            </td>
                                                                                            <td className="selectAmmount"
                                                                                                style={{
                                                                                                    verticalAlign: "middle",
                                                                                                    minWidth: "115px"
                                                                                                }}>
                                                                                                {ticket.available === true && (
                                                                                                    <select
                                                                                                        id={ticket.id}
                                                                                                        value={values.combosData.filter(combo => combo.comboId === ticket.id).length}
                                                                                                        onChange={handleSelectCombos}
                                                                                                        className="form-select"
                                                                                                        style={{
                                                                                                            maxWidth: "8rem",
                                                                                                            minWidth: "4rem"
                                                                                                        }}>
                                                                                                        <option
                                                                                                            key={0}>0
                                                                                                        </option>
                                                                                                        {(ticket.quantity / ticket.combo) < ticket.limit_per_order ? (Array.from(Array(Math.floor(ticket.quantity / ticket.combo)).keys()).map((i) => (
                                                                                                            <option
                                                                                                                key={i + 1}
                                                                                                                value={i + 1}>{i + 1}</option>
                                                                                                        ))) : (
                                                                                                            Array.from(Array(ticket.limit_per_order).keys()).map((i) => (
                                                                                                                <option
                                                                                                                    key={i + 1}
                                                                                                                    value={i + 1}>{i + 1}</option>
                                                                                                            ))
                                                                                                        )}
                                                                                                    </select>
                                                                                                )}
                                                                                                {ticket.available === false && ticket.showSoon === false && (
                                                                                                    <Button
                                                                                                        className="w-100"
                                                                                                        style={{
                                                                                                            maxWidth: "8rem",
                                                                                                            fontSize: "0.7rem",
                                                                                                            //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                                        }} color="error"
                                                                                                        variant="contained"
                                                                                                        disabled>
                                                                                                        Agotado
                                                                                                    </Button>
                                                                                                )}
                                                                                                {ticket.available === false && ticket.showSoon === true && (
                                                                                                    <Button
                                                                                                        className="w-100"
                                                                                                        style={{
                                                                                                            maxWidth: "8rem",
                                                                                                            fontSize: "0.55rem",
                                                                                                            //backgroundColor: "rgba(173,2,30,0.4)"
                                                                                                        }} color="info"
                                                                                                        variant="contained"
                                                                                                        disabled>
                                                                                                        Próximamente
                                                                                                    </Button>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : null}

                                                        {totalPrice.toLocaleString().replace(",", ".") !== "0" && (
                                                            <div className="row">
                                                                <div className="col-12 mt-3">
                                                                    <b style={{ fontSize: "1.5rem" }}>
                                                                        {event.service_charge ? "Subtotal" : "Total"}
                                                                    </b>
                                                                    <p style={{ fontSize: "2rem" }}>
                                                                        <b>
                                                                            {event.service_charge ? (
                                                                                <>
                                                                                    {tickets.some(
                                                                                        (ticket) =>
                                                                                            values.ticketsData.some(
                                                                                                (t) => t.eventTicketId === ticket.id
                                                                                            ) && ticket.currency === "USD"
                                                                                    )
                                                                                        ? "AR$ "
                                                                                        : "$ "}
                                                                                    {number_format(totalPrice, 2, ",", ".")}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {tickets.some(
                                                                                        (ticket) =>
                                                                                            values.ticketsData.some(
                                                                                                (t) => t.eventTicketId === ticket.id
                                                                                            ) && ticket.currency === "USD"
                                                                                    )
                                                                                        ? "AR$ "
                                                                                        : "$ "}
                                                                                    {number_format(totalPrice * (1 + fp_fee), 2, ",", ".")}
                                                                                </>
                                                                            )}
                                                                        </b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div
                                                            className={`row align-items-center mt-0 ${
                                                                totalPrice.toLocaleString().replace(",", ".") === "0" ? "mt-3" : ""
                                                            }`}
                                                        >
                                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => setOpen(true)}
                                                                    disabled={
                                                                        values.ticketsData.length === 0 && values.combosData.length === 0
                                                                    }
                                                                >
                                                                    {totalPrice > 0 ? "Comprar" : "Adquirir"}
                                                                </button>
                                                                {resaleLink && (
                                                                    <div className={"d-flex align-items-center"}>
                                                                        <a className="btn btn-primary" href={resaleLink} target={"_blank"}>
                                                                            <div className="row">
                                                                                <div className="col-12 d-flex align-items-center">
                                                                                    <GiCardExchange className={"me-2"} size="20" />
                                                                                    FlashTrade
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div>
                                                                            <>
                                                                                <Tooltip
                                                                                    title="Esta es una reventa 100% segura, oficial y transparente que te garantiza adquirir tickets auténticos"
                                                                                    placement="bottom"
                                                                                    enterTouchDelay={0}
                                                                                    leaveTouchDelay={5000}
                                                                                    enterDelay={500}
                                                                                >
                                                                                    <IconButton>
                                                                                        <InfoIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                    ) : (
                                                    <div className="row">
                                                    <div className="col-12">
                                                    <Alert variant="outlined" severity="info">
                                                    El evento no tiene tickets disponibles.
                                                    </Alert>
                                                    </div>
                                                    </div>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <Typography variant="h6" gutterBottom>
                                            Descripción
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12" style={{whiteSpace: "pre-wrap"}}>
                                        <p style={{color: "rgba(140,142,145,1.00)"}}>{event?.description}</p>
                                    </div>
                                </div>
                                <div className="row mt-4" id={"location-detail"}>
                                    <div className="col-12">
                                        <Typography variant="h6" gutterBottom>
                                            Lugar
                                        </Typography>
                                    </div>
                                </div>
                                {event?.venue && (
                                    <div className="row mt-2">
                                        <div className="col d-flex align-items-center">
                                    <span className="mb-0 d-flex align-items-center">
                                        <BiBuildingHouse className="me-2 icon" size={24} style={{flexShrink: 0}}/>
                                        {event?.venue}
                                    </span>
                                        </div>
                                    </div>
                                )}
                                <div className="row mt-2">
                                    <div className="col d-flex align-items-center">
                                <span className="mb-0 d-flex align-items-center">
                                    <HiOutlineLocationMarker className="me-2 icon" size={24} style={{flexShrink: 0}}/>
                                    {event?.full_location}
                                </span>
                                    </div>
                                </div>
                                {/*
                        <div className="row align-items-center">
                            <div className="col-lg-8 mt-3">
                                <div className="map-container">
                                    <MyMap address={event?.full_location} venue={event?.venue} />
                                </div>
                            </div>
                        </div>
                        */}
                                <div className="row mt-5">
                                    <div className="col-12" style={{
                                        whiteSpace: "pre-wrap",
                                        textAlign: "justify",
                                        textJustify: "inter-word"
                                    }}>
                                        <small className="text-muted" style={{fontSize: ".8rem"}}>La carga de productos
                                            y textos en el sistema licenciado son de exclusiva responsabilidad del
                                            Productor/Organizador. FlashPass no se hace responsable civil o
                                            jurídicamente por las declaraciones emitidas por estos en lo relativo a los
                                            productos vendidos en su plataforma. El Productor/Organizador es el único y
                                            exclusivo responsable de la producción y organización del Evento, en forma
                                            oportuna y en conformidad a la ley. <a
                                                href={"https://flashpass.com.ar/terminos-y-condiciones"}
                                                className="text-muted text-decoration-underline">Ver términos y
                                                condiciones</a>.</small>
                                    </div>
                                </div>
                                {relatedEvents?.length > 0 && producerRelatedEvents ? (
                                    <div className="row mt-5">
                                        <EventsSwiper target={"_blank"} events={relatedEvents}
                                                      title={"Más eventos de este organizador"} theme="dark"/>
                                    </div>
                                ) : relatedEvents?.length > 0 && !producerRelatedEvents && event?.show_related_events === 1 ? (
                                    <div className="row mt-5">
                                        <EventsSwiper target={"_blank"} events={relatedEvents}
                                                      title={"Otros eventos que te podrían interesar"} theme="dark"/>
                                    </div>
                                ) : null}
                            </>)}
                    </div>
                </div>
            </div>
            <Footer/>


            <StyledDialog onClose={handleClose} open={open} fullWidth className="eventDialogContainer">
                <div className="container pt-3" style={{position: "relative"}}>
                    <div className="closeIconDark p-1 align-items-center d-flex justify-content-center">
                        <IoClose size={25} color={"rgba(255, 255, 255, 0.38)"} onClick={handleClose}/>
                    </div>
                    <div className="p-2 p-md-3 p-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>
                                                <div className="d-none d-md-block">{label}</div>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        </div>
                        {activeStep === 0 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Tus items</p>
                                    </div>
                                </div>

                                {/* Display Selected Tickets */}
                                {tickets.map((ticket, index) => {
                                    const ticketDataArray = values.ticketsData.filter(
                                        (t) => t.eventTicketId === ticket.id
                                    );
                                    const ticketQuantity = ticketDataArray.length / (ticket.combo || 1);

                                    if (ticketQuantity > 0) {
                                        const ticketTotalPrice =
                                            (ticket.price) * ticketQuantity;

                                        return (
                                            <div className="row mb-2" key={`ticket-${index}`}>
                                                <div className="col-12">
                                                    <div
                                                        className="p-2"
                                                        style={{ borderRadius: "0.5rem", border: "solid 1px" }}
                                                    >
                                                        <div className="row align-items-center">
                                                            <div className="col">
                <span>
                  <b>{ticket.name}</b>
                </span>
                                                                <p className="mt-0 mb-0">
                                                                    {number_format(ticketTotalPrice, 2, ",", ".") === "0"
                                                                        ? "Sin Costo"
                                                                        : `${
                                                                            tickets.some(
                                                                                (t) =>
                                                                                    values.ticketsData.some(
                                                                                        (td) => td.eventTicketId === t.id
                                                                                    ) && t.currency === "USD"
                                                                            )
                                                                                ? "AR$"
                                                                                : "$"
                                                                        } ${
                                                                            event.service_charge
                                                                                ? number_format(ticketTotalPrice, 2, ",", ".")
                                                                                : number_format(
                                                                                    ticketTotalPrice * (1 + fp_fee),
                                                                                    2,
                                                                                    ",",
                                                                                    "."
                                                                                )
                                                                        }`}
                                                                </p>
                                                            </div>
                                                            <div className="col-auto text-end Buttons">
                                                                {ticketQuantity > 1 ? (
                                                                    <button
                                                                        style={{ border: "none", backgroundColor: "transparent" }}
                                                                        onClick={() => reduceTicket(ticket.id, ticket.combo)}
                                                                    >
                                                                        <AiOutlineMinus />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        style={{ border: "none", backgroundColor: "transparent" }}
                                                                        onClick={() => reduceTicket(ticket.id, ticket.combo)}
                                                                    >
                                                                        <BsTrash
                                                                            style={{ marginBottom: "2px", marginRight: "2px" }}
                                                                            size={14}
                                                                        />
                                                                    </button>
                                                                )}
                                                                <span>{ticketQuantity}</span>
                                                                {((ticketQuantity + 1) * (Number(ticket.combo) || 1)) <= ticket.limit_per_order &&
                                                                ((ticketQuantity + 1) * (Number(ticket.combo) || 1)) <= ticket.quantity ? (
                                                                    <button
                                                                        style={{ border: "none", backgroundColor: "transparent" }}
                                                                        onClick={() => addTicket(ticket.id, ticket.combo)}
                                                                    >
                                                                        <AiOutlinePlus />
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        style={{
                                                                            border: "none",
                                                                            backgroundColor: "transparent",
                                                                            opacity: "0.5",
                                                                        }}
                                                                        disabled
                                                                    >
                                                                        <AiOutlinePlus />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}

                                {/* Display Selected Combos */}
                                {values.combosData.map((comboData, index) => {
                                    // Find the corresponding combo from the combos array
                                    const selectedCombo = combos.find(c => c.id === comboData.comboId);

                                    // Render the combo item
                                    return (
                                        <div className="row mb-2" key={`combo-${index}`}>
                                            <div className="col-12">
                                                <div className="p-2" style={{ borderRadius: "0.5rem", border: "solid 1px" }}>
                                                    <div className="row align-items-center">
                                                        <div className="col">
                                                          <span>
                                                            <b>{comboData.comboName}</b>
                                                          </span>
                                                            <p className="mt-0 mb-0">
                                                                {number_format(
                                                                    comboData.comboPrice * comboData.quantity,
                                                                    2,
                                                                    ",",
                                                                    "."
                                                                ) === "0"
                                                                    ? "Sin Costo"
                                                                    : `${tickets.some(
                                                                        (t) => selectedTickets.includes(t.id) && t.currency === "USD"
                                                                    )
                                                                        ? "AR$"
                                                                        : "$"} ${
                                                                        event.service_charge
                                                                            ? number_format(
                                                                                comboData.comboPrice * comboData.quantity,
                                                                                2,
                                                                                ",",
                                                                                "."
                                                                            )
                                                                            : number_format(
                                                                                comboData.comboPrice * (1 + fp_fee) * comboData.quantity,
                                                                                2,
                                                                                ",",
                                                                                "."
                                                                            )
                                                                    }`}
                                                            </p>
                                                        </div>
                                                        <div className="col-auto text-end Buttons">
                                                            {/* Decrement Button */}
                                                            {comboData.quantity > 1 ? (
                                                                <button
                                                                    style={{
                                                                        border: "none",
                                                                        backgroundColor: "transparent"
                                                                    }}
                                                                    onClick={() => reduceCombo(comboData.comboId)}
                                                                >
                                                                    <AiOutlineMinus/>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    style={{
                                                                        border: "none",
                                                                        backgroundColor: "transparent"
                                                                    }}
                                                                    onClick={() => removeCombo(comboData.comboId)}
                                                                >
                                                                    <BsTrash
                                                                        style={{
                                                                            marginBottom: "2px",
                                                                            marginRight: "2px"
                                                                        }}
                                                                        size={14}
                                                                    />
                                                                </button>
                                                            )}
                                                            {/* Display Quantity */}
                                                            <span>{comboData.quantity}</span>
                                                            {/* Increment Button with Limits */}
                                                            {comboData.quantity < selectedCombo.quantity &&
                                                                (comboData.quantity + 1) * selectedCombo.tickets.reduce((a, b) => a + b.quantity, 0)
                                                            < selectedCombo.limitPerOrder ? (
                                                                <button
                                                                    style={{
                                                                        border: "none",
                                                                        backgroundColor: "transparent"
                                                                    }}
                                                                    onClick={() => addCombo(comboData.comboId)}
                                                                >
                                                                    <AiOutlinePlus />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    style={{
                                                                        border: "none",
                                                                        backgroundColor: "transparent",
                                                                        opacity: "0.5",
                                                                    }}
                                                                    disabled
                                                                >
                                                                    <AiOutlinePlus />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                {/* Total Calculations */}
                                <div className="row mt-3">
                                    <div className="col">
                                        <p className="mb-0">Costo de los items:</p>
                                        {event.service_charge ? (
                                            <p style={{ fontSize: ".8rem" }}>Cargos por servicio:</p>
                                        ) : (
                                            <p></p>
                                        )}
                                    </div>
                                    <div className="col-auto text-end">
                                        <p className="mb-0">
                                            {number_format(
                                                (totalTicketPrice + totalComboPrice) * (event.service_charge ? 1 : 1 + fp_fee),
                                                0,
                                                ",",
                                                "."
                                            ) === "0"
                                                ? "Sin Costo"
                                                : `${tickets.some(
                                                    (t) => selectedTickets.includes(t.id) && t.currency === "USD"
                                                )
                                                    ? "AR$"
                                                    : "$"} ${number_format(
                                                    (totalTicketPrice + totalComboPrice) *
                                                    (event.service_charge ? 1 : 1 + fp_fee),
                                                    2,
                                                    ",",
                                                    "."
                                                )}`}
                                        </p>
                                        {event.service_charge ? (
                                            <p style={{ fontSize: ".8rem" }}>
                                                {number_format(
                                                    (totalTicketPrice + totalComboPrice) * fp_fee,
                                                    0,
                                                    ",",
                                                    "."
                                                ) === "0"
                                                    ? "Sin Costo"
                                                    : `${tickets.some(
                                                        (t) => selectedTickets.includes(t.id) && t.currency === "USD"
                                                    )
                                                        ? "AR$"
                                                        : "$"} ${number_format(
                                                        (totalTicketPrice + totalComboPrice) * fp_fee,
                                                        2,
                                                        ",",
                                                        "."
                                                    )}`}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <hr className="mb-3" style={{ marginTop: 0 }} />
                                <div className="row mt-3">
                                    <div className="col">
                                        <p>
                                            <b>Total:</b>
                                        </p>
                                    </div>
                                    <div className="col-auto text-end">
                                        <p>
                                            <b>
                                                {number_format(
                                                    (totalTicketPrice + totalComboPrice) * (1 + fp_fee),
                                                    2,
                                                    ",",
                                                    "."
                                                ) === "0"
                                                    ? "Sin Costo"
                                                    : `${tickets.some(
                                                        (t) => selectedTickets.includes(t.id) && t.currency === "USD"
                                                    )
                                                        ? "AR$"
                                                        : "$"} ${number_format(
                                                        (totalTicketPrice + totalComboPrice) * (1 + fp_fee),
                                                        2,
                                                        ",",
                                                        "."
                                                    )}`}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleNext} disabled={!values.ticketsData.length && !values.combosData.length}>
                                            {activeStep === steps.length - 1 ? "Confirmar" : "Siguiente"}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 1 && (
                            <>
                                <div className="row mt-3 text-center d-md-none">
                                    <div className="col-12">
                                        <p>Datos del comprador</p>
                                    </div>
                                </div>
                                <form onSubmit={submitForm}>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField
                                                label="Nombre"
                                                variant="outlined"
                                                fullWidth
                                                value={values.buyerData.name}
                                                onChange={handleBuyerChange('name')}
                                                error={errors.buyerData.name}
                                                helperText={errorMessages.buyerData.name}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField
                                                label="Apellido"
                                                variant="outlined"
                                                fullWidth
                                                value={values.buyerData.surname}
                                                onChange={handleBuyerChange('surname')}
                                                error={errors.buyerData.surname}
                                                helperText={errorMessages.buyerData.surname}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <TextField
                                                label="DNI"
                                                variant="outlined"
                                                type="number"
                                                fullWidth
                                                value={values.buyerData.documentNumber}
                                                onChange={handleBuyerChange('documentNumber')}
                                                error={errors.buyerData.documentNumber}
                                                helperText={errorMessages.buyerData.documentNumber}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-3">
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                type="email"
                                                fullWidth
                                                value={values.buyerData.email}
                                                onChange={handleBuyerChange('email')}
                                                error={errors.buyerData.email}
                                                helperText={errorMessages.buyerData.email}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <TextField
                                                label="Confirmar Email"
                                                variant="outlined"
                                                type="email"
                                                fullWidth
                                                value={values.buyerData.confirmEmail}
                                                onChange={handleBuyerChange('confirmEmail')}
                                                error={errors.buyerData.confirmEmail}
                                                helperText={errorMessages.buyerData.confirmEmail}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mt-3">
                                            <TextField
                                                label="Teléfono"
                                                variant="outlined"
                                                type="number"
                                                fullWidth
                                                value={values.buyerData.phone}
                                                onChange={handleBuyerChange('phone')}
                                                error={errors.buyerData.phone}
                                                helperText={errorMessages.buyerData.phone}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6 mt-3">
                                            <FormControl
                                                fullWidth
                                                className="mb-3"
                                                error={errors.buyerData.province} // Add this line
                                            >
                                                <InputLabel id="province-select-label">Provincia de residencia</InputLabel>
                                                <Select
                                                    labelId="province-select-label"
                                                    id="province-select"
                                                    value={values.buyerData.province}
                                                    label="Provincia de residencia"
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            buyerData: {
                                                                ...values.buyerData,
                                                                province: event.target.value,
                                                            },
                                                        });
                                                        // Call inputValidation to validate province
                                                        inputValidation(event, 'province', 'buyerData');
                                                    }}
                                                    required
                                                >
                                                    {provinceList.map((province, key) => (
                                                        <MenuItem key={key} value={province.province_id}>
                                                            {province.province_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {/* Add helper text for errors */}
                                                <FormHelperText>{errorMessages.buyerData.province}</FormHelperText>
                                            </FormControl>
                                        </div>
                                    </div>
                                    {event.request_birth_date === 1 && (
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        renderInput={(props) => (
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                {...props}
                                                                error={errors.buyerData.birthDate}
                                                                helperText={errorMessages.buyerData.birthDate}
                                                            />
                                                        )}
                                                        label="Fecha de Nacimiento"
                                                        value={values.buyerData.birthDate}
                                                        inputFormat="dd/MM/yyyy"
                                                        maxDate={new Date()}
                                                        onChange={(newValue) => {
                                                            setValues((prevValues) => ({
                                                                ...prevValues,
                                                                buyerData: {
                                                                    ...prevValues.buyerData,
                                                                    birthDate: newValue,
                                                                },
                                                            }));
                                                            inputValidation({ target: { value: newValue } }, 'birthDate', 'buyerData');
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row text-center">
                                        <div className="col-12 mt-3">
                                            <Button onClick={handleBack}>Atrás</Button>
                                            <Button type="submit" disabled={disabledButton}>
                                                Siguiente
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                        {activeStep === 2 && (
                            <>
                                <div className="row mt-3 text-center d-md-none">
                                    <div className="col-12">
                                        <p>Datos de los tickets</p>
                                    </div>
                                </div>
                                {values.ticketsData.map((ticketData, index) => {
                                    const ticketId = ticketData.eventTicketId;
                                    const ticket = tickets.find((t) => t.id === ticketId);
                                    const genderRequired = ticket?.buyer_declared_gender === 1;

                                    return (
                                        <div key={`ticket-${index}`}>
                                            <div className="row mt-3">
                                                <div className="col-12">
          <span>
            Ticket {index + 1} - {ticket?.name}
          </span>
                                                </div>
                                            </div>
                                            <div className="row text-center">
                                                <div className="col-lg-6 mt-2">
                                                    <TextField
                                                        label="Nombre Completo"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={ticketData.ticketName || ''}
                                                        onChange={(e) => {
                                                            handleTicketChange('ticketName', index)(e);
                                                            inputValidation(e, 'ticketName', 'ticketsData', index);
                                                        }}
                                                        error={errors.ticketsData[index]?.ticketName}
                                                        helperText={errorMessages.ticketsData[index]?.ticketName}
                                                    />
                                                </div>
                                                <div className="col-lg-6 mt-2">
                                                    <TextField
                                                        label="DNI"
                                                        variant="outlined"
                                                        type="number"
                                                        fullWidth
                                                        value={ticketData.ticketDni || ''}
                                                        onChange={(e) => {
                                                            handleTicketChange('ticketDni', index)(e);
                                                            inputValidation(e, 'ticketDni', 'ticketsData', index);
                                                        }}
                                                        error={errors.ticketsData[index]?.ticketDni}
                                                        helperText={errorMessages.ticketsData[index]?.ticketDni}
                                                    />
                                                </div>
                                                {genderRequired && (
                                                    <div className="col-12 mt-2">
                                                        <FormControl
                                                            fullWidth
                                                            error={errors.ticketsData[index]?.ticketGender}
                                                        >
                                                            <InputLabel>Género</InputLabel>
                                                            <Select
                                                                value={ticketData.ticketGender || ''}
                                                                onChange={(e) => {
                                                                    handleTicketChange('ticketGender', index)(e);
                                                                    inputValidation(e, 'ticketGender', 'ticketsData', index);
                                                                }}
                                                                label={"Género"}
                                                            >
                                                                {ticketsGenderOptions.map((option) => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            <FormHelperText>
                                                                {errorMessages.ticketsData[index]?.ticketGender}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}

                                {/* Combos Products */}
                                {values.combosData.map((combo, comboIndex) =>
                                        combo.products.map((product, productIndex) => {
                                            // Find the corresponding combo in the combos data
                                            const comboData = combos.find(c => c.id === combo.comboId);
                                            // Find the corresponding product in the combo
                                            const comboProduct = comboData?.products.find(p => p.productId === product.productId);

                                            return comboProduct?.variantCategories.map((variantCategory, variantCategoryIndex) => {
                                                const isUnique = variantCategory.is_unique === 1 || variantCategory.is_unique === true;

                                                // Extract the variants array from the state
                                                const variants =
                                                    values.combosData?.[comboIndex]?.products?.[productIndex]?.variantCategories?.[variantCategoryIndex]?.variants || [];

                                                return (
                                                    <div key={`combo-${comboIndex}-product-${productIndex}-category-${variantCategoryIndex}`}>
                                                        <div className="row mt-3">
                                                            <div className="col-12">
                                                              <span>
                                                                Combo {comboIndex + 1} - {product.productName}
                                                              </span>
                                                            </div>
                                                        </div>
                                                        <div className="row text-center">
                                                            <div className="col-12 mt-2 text-start">
                                                                <FormControl
                                                                    fullWidth
                                                                    error={
                                                                        errors?.combosData?.[comboIndex]?.products?.[productIndex]?.variantCategories?.[variantCategoryIndex]?.variants
                                                                    }
                                                                >
                                                                    <InputLabel id={`variant-category-select-label-${comboIndex}-${productIndex}-${variantCategoryIndex}`}>
                                                                        {`${variantCategory.title} - ${isUnique ? 'Selecciona una opción' : 'Selecciona una o más opciones'}`}
                                                                    </InputLabel>
                                                                    <Select
                                                                        labelId={`variant-category-select-label-${comboIndex}-${productIndex}-${variantCategoryIndex}`}
                                                                        id={`variant-category-${comboIndex}-${productIndex}-${variantCategoryIndex}`}
                                                                        value={
                                                                            isUnique
                                                                                ? variants[0] || '' // Pass the first element or empty string
                                                                                : variants // Pass the entire array
                                                                        }
                                                                        label={`${variantCategory.title} - ${isUnique ? 'Selecciona una opción' : 'Selecciona una o más opciones'}`}
                                                                        onChange={handleProductVariantChange(
                                                                            'variants',
                                                                            comboIndex,
                                                                            productIndex,
                                                                            variantCategoryIndex,
                                                                            isUnique
                                                                        )}
                                                                        required
                                                                        multiple={!isUnique}
                                                                    >
                                                                        {variantCategory.variants.map((variant) => (
                                                                            <MenuItem key={variant.id} value={variant.id}>
                                                                                {variant.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {errors?.combosData?.[comboIndex]?.products?.[productIndex]?.variantCategories?.[variantCategoryIndex]?.variants && (
                                                                        <FormHelperText>
                                                                            {
                                                                                errorMessages?.combosData?.[comboIndex]?.products?.[productIndex]?.variantCategories?.[variantCategoryIndex]
                                                                                    ?.variants
                                                                            }
                                                                        </FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            });
                                        })
                                )}

                                {/* Combos Tickets */}
                                {values.combosData.map((combo, comboIndex) =>
                                        combo.tickets.map((ticket, ticketIndex) => {
                                            const eventTicket = tickets.find((t) => t.id === ticket.eventTicketId);
                                            const genderRequired = eventTicket?.buyer_declared_gender === 1;

                                            return (
                                                <div key={`combo-${comboIndex}-ticket-${ticketIndex}`}>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
            <span>
              Combo {combo.comboName} - Ticket {ticketIndex + 1} - {eventTicket?.name}
            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row text-center">
                                                        <div className="col-lg-6 mt-2">
                                                            <TextField
                                                                label="Nombre Completo"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={ticket.ticketName || ''}
                                                                onChange={(e) => {
                                                                    handleComboTicketChange('ticketName', comboIndex, ticketIndex)(e);
                                                                    inputValidation(
                                                                        e,
                                                                        'ticketName',
                                                                        'combosDataTickets',
                                                                        null,
                                                                        comboIndex,
                                                                        ticketIndex
                                                                    );
                                                                }}
                                                                error={
                                                                    errors.combosData?.[comboIndex]?.tickets?.[ticketIndex]?.ticketName
                                                                }
                                                                helperText={
                                                                    errorMessages.combosData?.[comboIndex]?.tickets?.[ticketIndex]
                                                                        ?.ticketName
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-lg-6 mt-2">
                                                            <TextField
                                                                label="DNI"
                                                                variant="outlined"
                                                                type="number"
                                                                fullWidth
                                                                value={ticket.ticketDni || ''}
                                                                onChange={(e) => {
                                                                    handleComboTicketChange('ticketDni', comboIndex, ticketIndex)(e);
                                                                    inputValidation(
                                                                        e,
                                                                        'ticketDni',
                                                                        'combosDataTickets',
                                                                        null,
                                                                        comboIndex,
                                                                        ticketIndex
                                                                    );
                                                                }}
                                                                error={
                                                                    errors.combosData?.[comboIndex]?.tickets?.[ticketIndex]?.ticketDni
                                                                }
                                                                helperText={
                                                                    errorMessages.combosData?.[comboIndex]?.tickets?.[ticketIndex]
                                                                        ?.ticketDni
                                                                }
                                                            />
                                                        </div>
                                                        {genderRequired && (
                                                            <div className="col-lg-6 mt-2">
                                                                <FormControl
                                                                    fullWidth
                                                                    error={
                                                                        errors.combosData?.[comboIndex]?.tickets?.[ticketIndex]
                                                                            ?.ticketGender
                                                                    }
                                                                >
                                                                    <InputLabel>Género</InputLabel>
                                                                    <Select
                                                                        value={ticket.ticketGender || ''}
                                                                        onChange={(e) => {
                                                                            handleComboTicketChange('ticketGender', comboIndex, ticketIndex)(e);
                                                                            inputValidation(
                                                                                e,
                                                                                'ticketGender',
                                                                                'combosDataTickets',
                                                                                null,
                                                                                comboIndex,
                                                                                ticketIndex
                                                                            );
                                                                        }}
                                                                    >
                                                                        {ticketsGenderOptions.map((option) => (
                                                                            <MenuItem key={option.id} value={option.id}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    <FormHelperText>
                                                                        {
                                                                            errorMessages.combosData?.[comboIndex]?.tickets?.[ticketIndex]
                                                                                ?.ticketGender
                                                                        }
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })
                                )}
                                <div className="row text-center">
                                    <div className="col-12 mt-3">
                                        <Button onClick={handleBack}>
                                            Atrás
                                        </Button>
                                        <Button onClick={handleNext} disabled={disabledButton}>
                                            Siguiente
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {activeStep === 3 && (
                            <>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Revisa tus datos</p>
                                    </div>
                                </div>
                                <div className="row mt-3 text-center">
                                    <div className="col-12">
                                        <p>Nombre: <b>{values.buyerData.name}</b></p>
                                        <p>Apellido: <b>{values.buyerData.surname}</b></p>
                                        <p>DNI: <b>{values.buyerData.documentNumber}</b></p>
                                        <p>Email: <b>{values.buyerData.email}</b></p>
                                        <p>Teléfono: <b>{values.buyerData.phone}</b></p>
                                        <div className="order-items-summary">
                                            <p>Tus items:</p>

                                            {/* Display selected tickets */}
                                            {tickets.map((ticket, index) => {
                                                const ticketQuantity =
                                                    values.ticketsData.filter((t) => t.eventTicketId === ticket.id).length /
                                                    (ticket.combo || 1);

                                                if (ticketQuantity > 0) {
                                                    return (
                                                        <p key={index}>
                                                            <b>{ticketQuantity}x {ticket.name}</b>
                                                        </p>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}

                                            {/* Display selected combos */}
                                            {values.combosData.map((combo, index) => (
                                                <div key={`combo-${index}`}>
                                                    <p>
                                                        <b>{combo.quantity}</b>x <b>{combo.comboName}</b>
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/*tickets.map(ticket => ticket.price / ticket.combo * selectedTickets.filter(tickets => tickets === ticket.id).length).reduce((a, b) => a + b, 0).toLocaleString().replace(",", ".") !== "0" ?
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <p className="select-payment-title">Selecciona tu método de pago:</p>
                                        <div className="payment-methods">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className="payment-method-opt" style={{border: `${values.paymentMethod === "payway" ? "5px solid #BA55D3" : "none"}`}}>
                                                        <input type="radio" name="payment-method" value="payway" checked={values.paymentMethod === "payway"} onChange={(e) => setValues({ ...values, paymentMethod: e.target.value })} />
                                                        <img className="img-fluid" src="/assets/images/Payway-Payment.png" alt="Payway Payment" />
                                                    </label>
                                                </div>
                                                <div className="col-6">
                                                    <label className="payment-method-opt" style={{border: `${values.paymentMethod === "mercadopago" ? "5px solid #BA55D3" : "none"}`}}>
                                                        <input type="radio" name="payment-method" value="mercadopago" checked={values.paymentMethod === "mercadopago"} onChange={(e) => setValues({ ...values, paymentMethod: e.target.value })} />
                                                        <img className="img-fluid" src="/assets/images/Mercado-Pago-Payment.png" alt="Mercado Pago Payment" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null*/}
                                <div className="row text-center mt-2">
                                    <div className="col-12">
                                        <p>Al confirmar la orden estas aceptando nuestros <a
                                            href={"https://flashpass.com.ar/terminos-y-condiciones"}
                                            style={{color: "whitesmoke"}} target={"_blank"}
                                            className="text-decoration-underline">términos y condiciones</a></p>
                                    </div>
                                </div>
                                <div className="row mt-2 text-center">
                                    <div className="col-12">
                                        <Button onClick={handleBack}>
                                            Atras
                                        </Button>
                                        <Button onClick={checkout}>
                                            Confirmar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </StyledDialog>
        </div>
    );
};

export default EventPreview;