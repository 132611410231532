// redux/layoutSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarCollapsed: false, // Default collapsed state
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action) => {
      state.sidebarCollapsed = action.payload;
    },
    toggleSidebarCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
  },
});

export const { setSidebarCollapsed, toggleSidebarCollapsed } = layoutSlice.actions;
export default layoutSlice.reducer;
